/**
 * TODO
 * - CLicking on component name in comment should open the relevant popover
 * - @mention users
 */

import React, { useState } from 'react';
import find from "lodash/find";
import startsWith from "lodash/startsWith";
import axios from "axios";
import {
  Avatar,
  Button,
  Drawer,
  Empty,
  Input,
  List
} from 'antd';
import moment from 'moment';
import API from "../../constants/api";
import { userDataStore } from "../../store/user";
import { Robot } from 'react-bootstrap-icons';

const { TextArea } = Input;

const CommentsDrawer = ({
  open,
  onClose, 
  projectDetails,
  updateProjectDetails,
  saveProjectDetailsObjects
}) => {
  const { nodes, edges } = projectDetails.threat_canvas;
  const userData = userDataStore((state) => state.userData);
  const [comments, setComments] = useState(projectDetails.comments);
  const [commentMessage, setCommentMessage] = useState("");
  const [isCommenting, setCommenting] = useState(false);

  const addComment = () => {
    const commentObj = {
      "user": {
          "user_id": userData.data.user_id,
          "full_name": userData.data.full_name,
          "picture": userData.data.picture
      },
      "message": {
          "body": commentMessage
      },
      "created_date": new Date().toUTCString(),
      "component": {
        "id": "",
        "title": "",
      },
    }
    
    /** Make API call to comment */
    setCommenting(true);
    comments.push(commentObj);
    saveProjectDetailsObjects("comments", comments, () => {
      setCommenting(false);
      setCommentMessage('');
    }, () => {
      setCommenting(false);
    });
  }

  return (
    <Drawer
      title="Comments"
      placement="right"
      onClose={onClose}
      open={open}
      mask={false}
      getContainer={false}
      width={500}
    >
      <div className="commentSection">
        
        {comments.length ? 
          <List itemLayout="vertical" className="comments">
            <List.Item className="bot-recco">
              <List.Item.Meta
                avatar={<Robot />}
                title="SA Agent"
                description="Security Assurance Agent"
                className="bot-avatar"
              />
              {/* <div className="comment-metadata">
                <span style={{color: "#444444", fontStyle: "Italic"}}>suggested</span>
              </div> */}
              <p>
                The Database component has 4 threats associated with it.
              </p>
            </List.Item>


            {comments.map(function (comment, index) {
              return (
                <List.Item key={index}>
                  <List.Item.Meta
                    avatar={<Avatar src={comment.user.picture} />}
                    title={comment.user.full_name}
                    description={moment(comment.created_date).fromNow()}
                  />
                  {(comment.component && comment.component.title) &&
                    <div className="comment-metadata">
                      <span style={{color: "#444444", fontStyle: "Italic"}}>commented on</span>
                      <Button type='link' style={{padding: '0 0 0 5px'}}>
                      {comment.component && startsWith(comment.component.id, 'reactflow__edge') ?
                        find(edges, {id: comment.component.id}).data.label :
                        find(nodes, {id: comment.component.id}).data.title
                      }
                      </Button>
                    </div>
                  }
                  {(comment.threat && comment.threat.title) &&
                    <div className="comment-metadata">
                      <span style={{color: "#444444", fontStyle: "Italic"}}>
                        {comment.threat.action ? 
                          <>deleted a threat:</> :
                          <>added a comment for threat:</>
                        }
                      </span>
                      <Button type='link' style={{padding: '0 0 0 5px'}}>
                        {comment.threat.title}
                      </Button>
                    </div>
                  }
                  <p>
                    {comment.message.body}
                  </p>
                </List.Item>)
            })}
          </List> :
          <div className="empty-state">
            <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description="No comments. Start collaborating" />
          </div>
        }
        
        <div className="commentBox">
          <TextArea value={commentMessage} placeholder="Type your comment" autoSize={{ minRows: 2, maxRows: 6}} onChange={(e) => {setCommentMessage(e.target.value)}} />
          <div className="buttonWrapper">
            <Button type="primary" onClick={addComment} loading={isCommenting}>
              {isCommenting ? `Commenting` : `Comment`}
            </Button>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
export default CommentsDrawer;