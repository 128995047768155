import React, { useState, useEffect } from "react";
import axios from "axios";
import PageLayout from "../pageLayout/application";
import {
  Button,
  Col,
  Row,
  message,
  Typography,
} from "antd";
import { Github, Slack, Amazon } from "react-bootstrap-icons";
import useDocumentTitle from "../../utils/pageTitle";
import Spacer from "../common/spacer";
import { userDataStore } from "../../store/user";
import API from "../../constants/api";

const { Title } = Typography;

const Workspace = () => {
  useDocumentTitle("Workspace");
  const [messageApi, contextHolder] = message.useMessage();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [workspaceDetails, setWorkspaceDetails] = useState({});
  const [githubIntegration, setGithubIntegration] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [status,setStatusDetail] = useState(null);
  const [repositories, setRepositories] = useState([]);
  const userData = userDataStore((state) => state.userData);

  const getWorkspaceDetails = async () => {
    try {
      const response = await axios.get(
        `${API.WORKSPACE_DETAILS}/${userData.data.organization_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: userData.access_token,
          },
        }
      );
      setWorkspaceDetails(response.data);
    } catch (err) {
      messageApi.open({
        type: "error",
        content: `There was an error: ${err.message}`,
        className: "error-message",
        duration: 5,
      });
    }
  };

  const fetchGithubData = async () => {
    try {
      const response = await axios.get(
        `${API.GITHUB_DATA}/${userData.data.organization_id}`
      );
      setStatusDetail(response.data.integrationStatus);
      setGithubIntegration(true);
      setIsLoading(false);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setGithubIntegration(false);
      } else {
        message.error("Failed to fetch GitHub data");
      }
      setIsLoading(false);
    }
  };

  const fetchRepositories = async () => {
    try {
      const response = await axios.get(
        `${API.GITHUB_REPOS}/${userData.data.organization_id}`
      );
      setRepositories(response.data);
    } catch (err) {
      message.error("Failed to fetch GitHub repositories");
    }
  };

  useEffect(() => {
    fetchGithubData();
  }, []);

  useEffect(() => {
    if (githubIntegration) {
      fetchRepositories();
    }
  }, [githubIntegration]);

  useEffect(() => {
    getWorkspaceDetails();
  }, []);

  const handleConnectGitHub = () => {
    window.location.href = `${API.AUTH_GITHUB}?organization_id=${userData.data.organization_id}`;
  };

  return (
    <>
      {contextHolder}
      <PageLayout>
        <Row>
          <Col span={14}>
            <Title>Workspace</Title>
          </Col>
          <Col span={10}>
            <div style={{ padding: "45px 0 0 0", textAlign: "right" }}>
              <Button type="primary">Invite Members</Button>
            </div>
          </Col>
        </Row>

        {workspaceDetails && (
          <div className="bold-form">
            <Row>
              <Col span={8}>
                <div className="form-field">
                  <label>Workspace name</label>
                  <p>{workspaceDetails.organization_name}</p>
                </div>
              </Col>
              <Col span={8}>
                <div className="form-field">
                  <label>Workspace type</label>
                  <p>{workspaceDetails.organization_type}</p>
                </div>
              </Col>
            </Row>

            <div className="form-field">
              <label>Admin contact</label>
              <p>{workspaceDetails.created_by_email}</p>
            </div>
          </div>
        )}
        <div style={{ borderBottom: "1px solid #EAEAEA" }}></div>
        <Spacer size={25} />
        <h2>
          Integrations
          <span style={{ color: "#cd58ff", fontSize: ".7rem" }}> COMING SOON</span>
        </h2>
        <Spacer size={25} />
        <Row gutter={25}>
          {!isLoading && (
            <Col span={24}>
              {githubIntegration ? (
                <div>
                  <h2>GitHub is {status}</h2>
                  <h3>Your Repositories:</h3>
                  <ul>
                    {repositories.map((repo) => (
                      <li key={repo.id}>{repo.name}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                <Button type="primary" onClick={handleConnectGitHub}>
                  Connect GitHub
                </Button>
              )}
            </Col>
          )}
          
          <Col span={8}>
            <div className="integration-card">
              <Row>
                <Col span={3} className="logo">
                  <Github />
                </Col>
                <Col span={21}>
                  <h3>Github</h3>
                  <p>
                    Bring your code and Github projects to integrate your threat models with the code.
                  </p> <br />
                  <Button type="primary" onClick={handleConnectGitHub}>
                    Connect GitHub
                  </Button><br /><br />
                </Col>
              </Row>
            </div>
          </Col>
          
          <Col span={8}>
            <div className="integration-card">
              <Row>
                <Col span={3} className="logo">
                  <Slack />
                </Col>
                <Col span={21}>
                  <h3>Slack</h3>
                  <p>
                    Bring Trusthive into slack with notifications and actions.
                  </p>
                </Col>
              </Row>
            </div>
          </Col>

          <Col span={8}>
            <div className="integration-card">
              <Row>
                <Col span={3} className="logo">
                  J
                </Col>
                <Col span={21}>
                  <h3>Jira</h3>
                  <p>
                    Sync to Jira projects/epics/etc. Create bugs & user stories
                    for your projects.
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
          
          <Col span={8}>
            <div className="integration-card">
              <Row>
                <Col span={3} className="logo">
                  <Amazon />
                </Col>
                <Col span={21}>
                  <h3>AWS</h3>
                  <p>Synchronize AWS DevOps with Trusthive.</p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </PageLayout>
    </>
  );
};

export default Workspace;