import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import find from "lodash/find";
import moment from 'moment';
import axios from "axios";
import PageLayout from "../pageLayout/application";
import { ReactComponent as OrganizeSVG } from "../../assets/organize.svg";
import {
  Alert,
  Button,
  Col,
  Input,
  Modal,
  message,
  Row,
  Select,
  Table,
  Tag,
  Typography,
  Tabs,
} from "antd";
import {
  ClockCircleOutlined,
  DeleteOutlined,
  ProjectOutlined,
  UpCircleOutlined,
  TagOutlined,
  UserOutlined
} from "@ant-design/icons";
import API from "../../constants/api";
import { userDataStore } from "../../store/user";
import { PROJECT_STATUS, PROJECT_TYPE } from "../../constants/app";
import { getBadgeColor, isUserAuthorized } from "../../utils/utils";
import useDocumentTitle from "../../utils/pageTitle";

const { Title } = Typography;
const { TextArea } = Input;
const { Column } = Table;
const { TabPane } = Tabs;

const tabs = [
  { key: "all", tab: "All", status: "All" },
  { key: "inProgress", tab: "In Progress", status: "In-Progress" },
  { key: "review", tab: "Review", status: "Review" },
  { key: "completed", tab: "Completed", status: "Completed" },
];


const Projects = () => {
  useDocumentTitle("Projects");
  const [messageApi, contextHolder] = message.useMessage();
  const userData = userDataStore((state) => state.userData);
  const [isNewProjectModalOpen, setNewProjectModalOpen] = useState(false);
  const [deleteProject, setDeleteProject] = useState({});
  const [isConfirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [projectsList, loadProjects] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const [isProjectsLoading, setIsProjectsLoading] = useState(true);
  const [projectType, setProjectType] = useState(null);

  const [formData, setFormData] = useState({
    projectTitle: "",
    projectDescription: "",
  });

  const navigate = useNavigate();

  const showNewProjectModal = () => {
    setNewProjectModalOpen(true);
  };

  const createNewProject = () => {
    const newProject = {
      project_title: formData.projectTitle,
      project_description: formData.projectDescription,
      project_status: PROJECT_STATUS.DRAFT,
      organization_id: userData.data.organization_id,
      project_type: projectType,
      threat_canvas: {},
      comments: [],
      threats: [],
      recommended_threats: [],
      collaborators: [
        {
          "user_id": userData.data.user_id,
          "full_name": userData.data.full_name,
          "picture": userData.data.picture,
          "access": "OWNER",
        }
      ]
    };

    axios
      .post(API.CREATE_PROJECT, newProject, {
        headers: {
          "Content-Type": "application/json",
          Authorization: userData.access_token,
        },
      })
      .then((response) => {
        setNewProjectModalOpen(false);
        navigate(`/project/plan/${response.data.project_id}`);
      })
      .catch((err) => {
        console.log("Error creating project:", err);
      });
  };

  const handleNewProjectModalCancel = () => {
    setNewProjectModalOpen(false);
  };

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };

  const getAllProjects = () => {
      axios
        .get(`${API.PROJECTS}/${userData.data.organization_id}`,
          {
            headers: {
            "Content-Type": "application/json",
            Authorization: userData.access_token,
          },
        })
        .then((response) => {
          setIsProjectsLoading(false);
          loadProjects(response.data);
        })
        .catch((err) => {
          messageApi.open({
            type: 'error',
            content: `There was an error: ${err}`,
            className: 'error-message',
            duration: 5
          });
        });
  }

  /** GET PROJECTS */
  useEffect(() => {
    getAllProjects();
  }, []);

  /** DELETE PROJECT */
  const confirmDeleteProject = (project) => {
    setConfirmDeleteModalOpen(true);
    setDeleteProject(project);
  };

  const handleConfirmDeleteProjectCancel = () => {
    setConfirmDeleteModalOpen(false);
  };

  const handleDeleteProject = () => {
    axios
      .delete(API.DELETE_PROJECT, {
        headers: {
          "Content-Type": "application/json",
          Authorization: userData.access_token,
        },
        data: {
          project_id: deleteProject.project_id
        }
      })
      .then((response) => {
        messageApi.open({
          type: 'success',
          content: `Project has been deleted`,
          className: 'success-message',
          duration: 5
        });
        setConfirmDeleteModalOpen(false);
        getAllProjects();
      })
      .catch((err) => {
        messageApi.open({
          type: 'error',
          content: `There was an error: ${err}`,
          className: 'error-message',
          duration: 5
        });
      });
  };

  return (
    <>
      {contextHolder}
    <PageLayout>
      <Row>
        <Col span={14}>
          <Title>Projects</Title>
        </Col>
        <Col span={10}>
          <div style={{ padding: "45px 0 0 0", textAlign: "right" }}>
            <Button type="primary" onClick={showNewProjectModal}>
              New Project
            </Button>
          </div>
        </Col>
      </Row>
      <div className="hero-banner">
        <Row>
          <Col span={6}>
            <OrganizeSVG style={{width:250, height: 200}} />
          </Col>
          <Col span={18}>
            <h3>Organize your services, applications or products via projects</h3>
            <p>
              Think of each project as an end-to-end deployable service, application or product. 
              Each project will have it's own lifecycle like: requirements gathering, design & threat modeling phase,
              development, security review and deploy to production phase.
            </p>
          </Col>
        </Row>

      </div>

      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        {tabs.map((tab) => (
          <TabPane tab={tab.tab} key={tab.key} />
        ))}
      </Tabs>

      <Table
        loading={isProjectsLoading}
        rowKey="project_id"
        dataSource={
          activeTab === "all"
            ? projectsList
            : projectsList.filter(
                (project) =>
                  project.project_status ===
                  tabs.find((tab) => tab.key === activeTab).project_status
              )
        }
      >
        <Column
          title={
            <>
              <span>
                <ProjectOutlined style={{ paddingRight: "8px" }} />
                Project Title
              </span>
            </>
          }
          dataIndex="title"
          key="title"
          render={(project_title, data) => {
            return (
              <>
                {data.project_status === "In review" ? (
                  <Link to={`/project/details/review/${data.project_id}`}>
                    {data.project_title}
                  </Link>
                ) : (
                  <Link to={`/project/plan/${data.project_id}`}>
                    {data.project_title}
                  </Link>
                )}
              </>
            );
          }}
        />

        <Column
          title={
            <>
              <span>
                <UpCircleOutlined style={{ paddingRight: "8px" }} />
                Status
              </span>
            </>
          }
          dataIndex="project_status"
          key="project_status"
          render={(project_status) => (
            <Tag color={getBadgeColor(project_status)}>{project_status}</Tag>
          )}
        />

        <Column
          title={
            <>
              <span>
                <ClockCircleOutlined style={{ paddingRight: "8px" }} />
                Created Date
              </span>
            </>
          }
          dataIndex="lastModifiedDate"
          key="lastModifiedDate"
          render={(projectname, data) => {
            return <>{moment(data.created_date).format('MMM Do')}</>;
          }}
        />

        <Column
          title={
            <>
              <span>
                <ClockCircleOutlined style={{ paddingRight: "8px" }} />
                Last Updated
              </span>
            </>
          }
          dataIndex="lastModifiedDate"
          key="lastModifiedDate"
          render={(projectname, data) => {
            return <>{moment(data.last_modified_on_date).fromNow()}</>;
          }}
        />

        <Column
          align="center"
          title={
            <>
              <span>
                <TagOutlined style={{ paddingRight: "8px" }} />
                Number of Threats
              </span>
            </>
          }
          dataIndex="noOfThreats"
          key="noOfThreats"
          render={(projectname, data) => {
            return <>{data.threats.length}</>;
          }}
        />

        <Column
          title={
            <>
              <span><UserOutlined style={{ paddingRight: "8px" }} /> Created by </span>
            </>
          }
          dataIndex="team"
          key="team"
          render={(projectname, data) => {
            const creator = find(data.collaborators, { access: "OWNER" });
            if(creator) {
              return <>{creator.full_name}</>;
            }
          }}
        />

        <Column
          dataIndex="icon"
          key="icon"
          render={(project_id, data) => (
            <Button type="link" danger onClick={() => {confirmDeleteProject(data)}}>
              <DeleteOutlined />
            </Button>
          )}
        />
      </Table>

      <Modal
        title={<><ProjectOutlined /> Start a new project</>}
        open={isNewProjectModalOpen}
        onOk={createNewProject}
        onCancel={handleNewProjectModalCancel}
        width={800}
        okText="Submit"
        okButtonProps = {formData.projectTitle === '' || formData.projectDescription === '' ? {disabled : true} : null}
      >
        {/* Use the single state object for input values */}
        <div className="form-field">
          <label>Project Title*</label>
          <Input
            placeholder="Enter the name of a project"
            value={formData.projectTitle}
            onChange={(e) => setFormData({ ...formData, projectTitle: e.target.value })}
          />
        </div>
        <div className="form-field">
          <label>Project type* <span>This will load up the relevant controls and data</span></label>
          {PROJECT_TYPE.map((p, index) => (
            <div
              key={index} 
              className={"project-type-blob " + (p.key === projectType ? 'selected' : '')}
              onClick={() => setProjectType(p.key)}
            >
              <h2>{p.icon}</h2>
              {p.label}
            </div>
          ))}
        </div>
        <div className="form-field">
          <label>Description*<span>Explain the project in as much detail so our AI can set it up for you.</span></label>
          <TextArea
            rows={4}
            placeholder="More details about this project"
            value={formData.projectDescription}
            onChange={(e) => setFormData({ ...formData, projectDescription: e.target.value })}
          />
        </div>

        <div className="form-field">
          <label>Code</label>
          <Input
            placeholder="Enter the git URL"
            value={formData.projectTitle}
            onChange={(e) => setFormData({ ...formData, projectTitle: e.target.value })}
          />
        </div>

        {/* <div className="form-field">
          <label>Tags</label>
          <Select
            mode="tags"
            // onChange={handleChange}
            tokenSeparators={[","]}
            // options={options}
          />
        </div> */}
      </Modal>

      
      {/* DELETE PROJECT MODAL */}
      <Modal
        title="Are you sure you want to delete this project?"
        open={isConfirmDeleteModalOpen}
        onOk={handleDeleteProject}
        okButtonProps={{
          type: "primary",
          danger: true,
        }}
        onCancel={handleConfirmDeleteProjectCancel}
        type="error"
        className="danger-modal"
      >
        <Alert
          message="You are about to delete the below project."
          type="error"
          showIcon
        />
        <br />
        This action cannot be reversed. Are you sure you wish to continue?
        <br />
        <br />
        <Tag color="#f50">{deleteProject.project_title}</Tag>
      </Modal>

    </PageLayout>
    </>
  );
};

export default Projects;