/**
 * Refactor Avatar
 */

import React from "react";
import { Link } from "react-router-dom";
import {
  Badge,  
  Button,
  Col,
  Menu,
  Row,
  Space,
  Tooltip
} from 'antd';
import {
  ArrowLeftOutlined,
  BellOutlined,
  CheckCircleOutlined,
  CommentOutlined,
  EllipsisOutlined,
  FormOutlined,
  SettingOutlined,
  ShareAltOutlined,
  SecurityScanOutlined,
  WarningOutlined
} from "@ant-design/icons";
import { ExclamationCircleFill } from "react-bootstrap-icons";



const ProjectNavBar = (propsData) => {
    const {
      activeTab,
      projectDetails,
      recommendedThreatsCount,
      planCommentsDrawer,
      setPlanCommentsDrawer,
      designCommentsDrawer,
      setDesignCommentsDrawer,
      threatsDrawer,
      setThreatsDrawer,
      securityChecklistDrawer,
      setSecurityChecklistDrawer} = propsData;

    const isEU_Flag = projectDetails.meta_data && projectDetails.meta_data.is_EU ? true : false;

    return (
        <>
          <div className="page-nav-bar">
            <Row>
              <Col span={14}>
                <Menu className="page-primary-nav" mode="horizontal">
                  <Menu.Item key="plan" className={`${activeTab == 'plan' ? 'ant-menu-item-selected' : ''}`}>
                    <Link to={`/project/plan/${projectDetails.project_id}`}>Project Details</Link>
                  </Menu.Item>
                  <Menu.Item key="design" className={`${activeTab == 'design' ? 'ant-menu-item-selected' : ''}`}>
                    <Link to={`/project/design/${projectDetails.project_id}`}>
                      Secure Design
                      {isEU_Flag &&
                        <span className="warning-icon"><ExclamationCircleFill /></span>
                      }
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="secure" className={`${activeTab == 'secure' ? 'ant-menu-item-selected' : ''}`}>
                    <Link to={`/project/secure/${projectDetails.project_id}`}>
                      Security & Compliance
                      {isEU_Flag &&
                        <span className="warning-icon"><ExclamationCircleFill /></span>
                      }
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="build" className={`${activeTab == 'build' ? 'ant-menu-item-selected' : ''}`}>
                    <Link to={`/project/build/${projectDetails.project_id}`}>
                      Build & Deploy
                      {isEU_Flag &&
                        <span className="warning-icon"><ExclamationCircleFill /></span>
                      }
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="overview" className={`${activeTab == 'overview' ? 'ant-menu-item-selected' : ''}`}>
                    <Link to={`/project/overview/${projectDetails.project_id}`}>
                      Production & Release
                      {isEU_Flag &&
                        <span className="warning-icon"><ExclamationCircleFill /></span>
                      }
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="manage" className={`${activeTab == 'manage' ? 'ant-menu-item-selected' : ''}`}>
                    <Link to={`/project/manage/${projectDetails.project_id}`}>Manage & Monitor</Link>
                  </Menu.Item> */}
                </Menu>
              </Col>
              <Col span={5} offset={5} align="right">
                <div className="navbar-btn-wrapper">
                  
                  {activeTab == 'plan' &&
                    <>
                      <Tooltip placement="bottom" title={"Comment"}>
                          <Button className="navbar-btn-icon" onClick={() => setPlanCommentsDrawer(!planCommentsDrawer)}>
                            <CommentOutlined />
                          </Button>
                      </Tooltip>
                    </>
                  }
                  {activeTab == 'design' &&
                    <>
                      {/* <Tooltip placement="bottom" title={"Security Checklist"}>
                          <Button
                            className="navbar-btn-icon"
                            onClick={() => setSecurityChecklistDrawer(!securityChecklistDrawer)}
                          >
                            <CheckCircleOutlined />
                          </Button>
                      </Tooltip> */}
                      <Tooltip placement="bottom" title={"Threats"}>
                        <Badge count={recommendedThreatsCount && recommendedThreatsCount.length}>
                          <Button
                            onClick={() => setThreatsDrawer(!threatsDrawer)}
                            style={{ border: 0, padding: 0 }}
                          >
                            <SecurityScanOutlined style={{ fontSize: "21px" }} />
                          </Button>
                        </Badge>
                      </Tooltip>
                      <Tooltip placement="bottom" title={"Comment"}>
                          <Button className="navbar-btn-icon" onClick={() => setDesignCommentsDrawer(!designCommentsDrawer)}>
                            <CommentOutlined />
                          </Button>
                      </Tooltip>
                    </>
                  }
                </div>
              </Col>
            </Row>
          </div>
        </>
    )
};

export default ProjectNavBar;