import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import filter from "lodash/filter";
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import { ReactComponent as LogoSVG } from '../../assets/brand-logo.svg';
import moment from 'moment';
import axios from "axios";
import {
  ArrowLeftOutlined,
  BellOutlined,
  CheckCircleOutlined,
  CommentOutlined,
  EllipsisOutlined,
  FormOutlined,
  SettingOutlined,
  ShareAltOutlined,
  SecurityScanOutlined,
  WarningOutlined
} from "@ant-design/icons";
import {
  Avatar as AntAvatar,
  Badge,
  Button,
  Col,
  Dropdown,
  Layout,
  Modal,
  message,
  Row,
  Space,
  theme,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import ProjectNavBar from '../projectDetails/components/projectNavbar';
import ProjectOverview from './projectOverview';
import ProjectPlan from './projectPlan';
import ProjectDesign from './projectDesign';
import ProjectBuild from './projectBuild';
import ProjectManage from './projectManage';
import ProjectSecurity from './projectSecurity';
import { PROJECT_STATUS } from "../../constants/app";
import DocDrawer from "../canvas/docDrawer";
import CommentsDrawer from "../canvas/commentsDrawer";
import ThreatsDrawer from "../canvas/threatsDrawer";
import ChecklistDrawer from "../canvas/checklistDrawer";
import ShareModal from "../canvas/shareModal";
import ProjectStatusModal from "../projects/projectStatusModal";
import { getBadgeColor } from "../../utils/utils";
import Avatar from "../common/Avatar";
import TerraformDrawer from "../canvas/terraformDrawer";
import { CaretRight, CaretRightFill, Code, FileEarmarkSpreadsheet, FiletypePdf, Robot, Share } from "react-bootstrap-icons";
import Feedback from '../common/Feedback';
import API from "../../constants/api";
import { userDataStore } from "../../store/user";
import useDocumentTitle from '../../utils/pageTitle';


const { Header, Content } = Layout;
const { Title } = Typography;
const projectDetailsActions = [
    {
        label: <Button type="link" icon={<Share />}  > Share project </Button>,
        key: '0',
    },
    {
        label: <Button type="link" icon={<FileEarmarkSpreadsheet />}  > Download compliance report </Button>,
        key: '1',
    },
    {
        label: <Button type="link" icon={<FiletypePdf />}  > Download project </Button>,
        key: '2',
    },
]

const aiTriggerDescriptionStrings = ["EU", "European Union", "France", "Germany"];


const ProjectDetailsLayout = () => {
    // <!-- eslint-skip -->
    const userData = userDataStore(state => state.userData);
    const [projectDetails, updateProjectDetails] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [recommendedThreats, setRecommendedThreats] = useState([]);
    const [isPageDirty, setIsPageDirty] = useState(false);
    const [isProjectSaving, setIsProjectSaving] = useState(false);
    const [isShareModalOpen, setShareModalOpen] = useState(false);
    const [isProjectStatusModalOpen, setProjectStatusModalOpen] = useState(false);
    const {projectId, view} = useParams();
    const [bigChangeModal, setBigChangeModal] = useState(false);

    /** DRAWER PROPS */
    const [ planCommentsDrawer, setPlanCommentsDrawer] = useState(false);
    const [ designCommentsDrawer, setDesignCommentsDrawer] = useState(false);
    const [ threatsDrawer, setThreatsDrawer] = useState(false);
    const [ securityChecklistDrawer, setSecurityChecklistDrawer] = useState(false);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    /** GET PROJECTS DETAILS */
    useEffect(() => {
        axios.get(API.PROJECT_DETAILS, {
        params:{
            project_id: projectId,
            organization_id: userData.data.organization_id
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: userData.access_token
        }
        }).then((response) => {
            const {data} = response;
            const { recommended_threats } = data;
            updateProjectDetails(data);
            // setRecommendedThreats(recommended_threats);
        }).catch((err) => {
        messageApi.open({
            type: 'error',
            content: `There was an error: ${err}`,
            className: 'error-message',
            duration: 10
        });
        });

        const onBeforeUnload = (e) => {
            if (isPageDirty) {
                e.preventDefault();
                e.returnValue = "";
            }
        };
        window.addEventListener("beforeunload", onBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", onBeforeUnload);
        };
    }, []);


    const saveProjectDetails = () => {
        setIsProjectSaving(true);
        const isCurrentUserCollaborator = find(projectDetails.collaborators, { user_id: userData.data.user_id }) ? true : false;
        if(!isCurrentUserCollaborator) {
            const o = {
            "user_id": userData.data.user_id,
            "full_name": userData.data.full_name,
            "picture": userData.data.picture,
            "access": "EDIT",
            }
            projectDetails.collaborators.push(o);
        }
        if(aiTriggerDescriptionStrings.some(substring => projectDetails.project_description.includes(substring))) {
            projectDetails.meta_data = {is_EU : true};
            projectDetails.project_status = PROJECT_STATUS.DRAFT;
            setBigChangeModal(true);
        }
        
        axios.put(`${API.UPDATE_PROJECT}/${projectId}`, projectDetails, {
            headers: {
            "Content-Type": "application/json",
            Authorization: userData.access_token
            }
        }).then((response) => {
            setIsPageDirty(false);
            setIsProjectSaving(false);
            updateProjectDetails(response.data.project_data);
            messageApi.open({
            type: 'success',
            content: 'All changes saved successfully.',
            className: 'success-message',
            duration: 5
            });
        }).catch((err) => {
            setIsProjectSaving(false);
            messageApi.open({
            type: 'error',
            content: `There was an error: ${err}`,
            className: 'error-message',
            duration: 10
            });
        });
    };


    /** GET THREAT RECCOMENDATIONS */
    const doThreatRecommendations = (node) => {
        axios.get(API.THREAT_INTEL, {
        params:{
            organization_id: userData.data.organization_id,
            component: node.data.key
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: userData.access_token
        }
        }).then((response) => {
        if(response.data.length > 0) {
            const newObj = [...recommendedThreats];
            response.data.forEach((o) => {
            newObj.push(o);
            });
            setRecommendedThreats(uniqBy(newObj, 'threat_id'));
            saveProjectDetailsObjects('recommended_threats', uniqBy(newObj, 'threat_id'));
        }
        }).catch((err) => {
        console.log('An error happened' + err);
        });
    }

    /** SAVE PARTIAL OBJJECTS FOR PROJECT */
    const saveProjectDetailsObjects = (param, obj, successCallback, errorCallback) => {
        axios.put(`${API.UPDATE_PROJECT}/${projectId}`, {
          [param]: obj
        },{
          headers: {
            "Content-Type": "application/json",
            Authorization: userData.access_token
          }
        }).then((response) => {
          updateProjectDetails(response.data.project_data);
          if(successCallback) {
            successCallback();
          }      
        }).catch((err) => {
          console.log('There was an error' + err);
          if(errorCallback) {
            errorCallback();
          }
        });
      };

    return (
        <>
        {projectDetails && projectDetails.project_id &&
        <Layout style={{ minHeight: '100vh' }}>
            {contextHolder}
            <Header style={{ padding: 0, background: colorBgContainer, borderBottom: '1px solid rgb(204, 204, 204)' }}>
                <Row>
                    <Col span={1}>
                        <div style={{ margin: "10px 0 10px 15px", verticalAlign: "middle" }}>
                        <LogoSVG />
                        </div>
                    </Col>
                    <Col span={10}>
                        <div style={{ padding: "15px 25px 0 15px" }}>
                        <Title level={3} style={{ margin: "0" }}>
                            <Link to="/projects">
                            <Button type="text" style={{ marginRight: "15px" }}>
                                <ArrowLeftOutlined />
                            </Button>
                            </Link>
                            {projectDetails && projectDetails.project_title ? (
                            <Tag
                                color={getBadgeColor(projectDetails.project_status)}
                                style={{ margin: "0 15px 0 0", verticalAlign: "middle", cursor: "pointer"}}
                                onClick={() => setProjectStatusModalOpen(true)}
                            >
                                {projectDetails && projectDetails.project_status ? projectDetails.project_status.toUpperCase() : ''}
                                <CaretRightFill style={{verticalAlign: 'middle'}} />
                            </Tag>
                            ) : (
                            <></>
                            )}
                            {projectDetails && projectDetails.project_title
                            ? projectDetails.project_title
                            : ''}
                            <span className="saveTimestamp">Saved {moment(projectDetails.last_modified_on_date).fromNow()}</span>
                        </Title>
                        </div>
                    </Col>
                    <Col align="right" span={6}>
                            {isPageDirty &&
                                <span style={{color: 'rgb(212, 83, 6)', fontStyle: 'italic', fontWeight: 400}}>
                                <WarningOutlined /> There are unsaved changes. Please click on Save Project
                                </span>
                            }
                    </Col>
                    <Col align="right" span={4}>
                        <Space size={20}>
                            {projectDetails.collaborators &&
                                <div style={{paddingTop: 10}}>
                                <AntAvatar.Group>
                                    {projectDetails.collaborators.map(function (user, index) {
                                        return (
                                        <Avatar key={index} userData={user} tooltip={true} />
                                        )
                                    })}
                                </AntAvatar.Group>
                                </div>
                            }
                            {/* <Tooltip placement="bottom" title={"Share"}>
                                <Button
                                onClick={() => setShareModalOpen(true)}
                                style={{ border: 0, padding: 0 }}
                                >
                                    <ShareAltOutlined style={{ fontSize: "26px" }} />
                                </Button>
                            </Tooltip> */}
                            <Tooltip placement="bottom" title={"Notifications"}>
                                <BellOutlined style={{ fontSize: "26px" }} />
                            </Tooltip>
                            {/* <Tooltip placement="bottom" title={"Settings"}>
                                <SettingOutlined style={{ fontSize: "26px" }} />
                            </Tooltip> */}
                        </Space>
                    </Col>
                    <Col span={3} align="center">
                        <Space size={20}>
                        <Button
                            type="primary"
                            onClick={saveProjectDetails}
                            loading={isProjectSaving}
                        >
                            {isProjectSaving ? `Saving project` : `Save project`}
                        </Button>

                        <Dropdown menu={{ items: projectDetailsActions }}>
                            <Button shape="circle" icon={<EllipsisOutlined />} color="primary" variant="outlined" />
                        </Dropdown>
                        </Space>
                    </Col>
                </Row>
            </Header>
            <Content>
                <div style={{ flexDirection: 'column', display: 'flex', flexGrow: 1, height: 'calc(100vh - 68px)' }}>
                    <ProjectNavBar
                        activeTab = {view}
                        projectDetails = {projectDetails}
                        planCommentsDrawer = {planCommentsDrawer}
                        setPlanCommentsDrawer = {setPlanCommentsDrawer}
                        designCommentsDrawer = {designCommentsDrawer}
                        setDesignCommentsDrawer = {setDesignCommentsDrawer}
                        threatsDrawer = {threatsDrawer}
                        setThreatsDrawer = {setThreatsDrawer}
                        securityChecklistDrawer = {securityChecklistDrawer}
                        setSecurityChecklistDrawer = {setSecurityChecklistDrawer}
                    />

                    {view === 'overview' &&
                        <ProjectOverview />
                    }
                    
                    {view === 'plan' &&
                        <ProjectPlan
                            projectDetails = {projectDetails}
                            updateProjectDetails = {updateProjectDetails}
                            saveProjectDetailsObjects = {saveProjectDetailsObjects}
                            planCommentsDrawer = {planCommentsDrawer}
                            setPlanCommentsDrawer = {setPlanCommentsDrawer}
                        />
                    }

                    {view === 'design' &&
                        <ProjectDesign
                            projectDetails = {projectDetails}
                            updateProjectDetails = {updateProjectDetails}
                            doThreatRecommendations = {doThreatRecommendations}
                            saveProjectDetailsObjects = {saveProjectDetailsObjects}
                            recommendedThreats = {recommendedThreats}
                            designCommentsDrawer = {designCommentsDrawer}
                            setDesignCommentsDrawer = {setDesignCommentsDrawer}
                            threatsDrawer = {threatsDrawer}
                            setThreatsDrawer = {setThreatsDrawer}
                            securityChecklistDrawer = {securityChecklistDrawer}
                            setSecurityChecklistDrawer = {setSecurityChecklistDrawer}
                        />
                    }

                    {view === 'build' &&
                        <ProjectBuild
                            projectDetails = {projectDetails}
                            updateProjectDetails = {updateProjectDetails}
                            doThreatRecommendations = {doThreatRecommendations}
                            saveProjectDetailsObjects = {saveProjectDetailsObjects}
                        />
                    }

                    {view === 'manage' &&
                        <ProjectManage />
                    }

                    {view === 'secure' &&
                        <ProjectSecurity 
                            projectDetails = {projectDetails}
                            updateProjectDetails = {updateProjectDetails}
                            saveProjectDetailsObjects = {saveProjectDetailsObjects}
                        />
                    }
                </div>
            </Content>
            <ProjectStatusModal
                projectDetails={projectDetails}
                isModalOpen={isProjectStatusModalOpen}
                setModalOpen={setProjectStatusModalOpen}
                saveProjectDetailsObjects={saveProjectDetailsObjects}
            />

            <Modal
                title="Looks like you made a big change"
                open={bigChangeModal}
                onOk={() => setBigChangeModal(false)}
                okButtonProps={{
                    type: "primary",
                }}
                onCancel={() => setBigChangeModal(false)}
                width={800}
            >
                <div className="bot-message-blurb">
                    <AntAvatar src={<Robot />} className="bot-avatar"/>
                    <div className="message">
                        <h4>SA Agent</h4>
                        <p>
                            Project details have been updated <strong>Regions: EU</strong>.<br />
                            To proceed with this change, click the OK button.<br />
                            Once confirmed, we will notify all relevant team members and update the associated sections of the project accordingly.
                        </p>
                    </div>
                </div>
            </Modal>


        </Layout>
    }
    </>
)};

export default ProjectDetailsLayout;