import React, { useCallback, useState, useEffect, useRef,useMemo } from "react";
import axios from "axios";
import  {
  ReactFlow,
  Node,
  ReactFlowProvider,
  useReactFlow,
  Background,
  BackgroundVariant,
  useStoreApi,
  MarkerType,
  useNodesState,
  useEdgesState,
  addEdge,
  Connection,
  Controls,
  Panel,
} from "@xyflow/react";
import API from "../../constants/api";
import { userDataStore } from "../../store/user";
import { isEqual } from "lodash";
import "./canvas.css";
import "@xyflow/react/dist/style.css";
import SelectedNodesToolbar from "./SelectedNodesToolbar";
import Sidebar from "./sidebar";
import "./canvas.css";
import CustomNode from "./customNode";
import GroupNode from "./GroupNode";
import CustomEdge from "./customEdge";
import "@xyflow/react/dist/style.css";
import "@xyflow/react/dist/style.css";
import {
  Button,
  Modal,
  Select
} from "antd";

import { sortNodes, getId, getNodePositionInsideParent } from "./utils";



const proOptions = {
  hideAttribution: true,
};

const onDragOver = (event) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = "move";
};


const defaultEdgeOptions = {
  style: {
    strokeWidth: 2,
  },
  markerEnd: {
    type: MarkerType.ArrowClosed,
  },
};

const Canvas = ({ projectDetails, updateProjectDetails, saveProjectDetailsObjects, doThreatRecommendations, setPageDirty}) => {
  const userData = userDataStore((state) => state.userData);
  const [nodeIsProject, setNodeIsProject] = useState(null);
  const [nodeIsProjectModal, setNodeIsProjectModal] = useState(false);
  const [listOfProjects, setlistOfProjects] = useState(null);

  const getAllProjects = () => {
    axios
      .get(`${API.PROJECTS}/${userData.data.organization_id}`,
        {
          headers: {
          "Content-Type": "application/json",
          Authorization: userData.access_token,
        },
      })
      .then((response) => {
        setlistOfProjects(response.data);
      })
      .catch((err) => {
        // messageApi.open({
        //   type: 'error',
        //   content: `There was an error: ${err}`,
        //   className: 'error-message',
        //   duration: 5
        // });
        console.log('There was an error' + err)
      });
}

  const edgeTypes = useMemo(() => ({
    customEdge: (props) => (
      <CustomEdge
        {...props}
        projectDetails={projectDetails}
        saveProjectDetailsObjects={saveProjectDetailsObjects}
        handleEdgeLabelChange={handleEdgeLabelChange}
        handleReverseDirection={handleReverseDirection}
        handleBidirection={handleBidirection}
      />
    ),
  }), [projectDetails, saveProjectDetailsObjects]);
  const nodeTypes = useMemo(() => ({
    node: (props) => (
      <CustomNode
        {...props}
        projectDetails={projectDetails}
        saveProjectDetailsObjects={saveProjectDetailsObjects}
        handleNodeTitleChange={handleNodeTitleChange}
      />
    ),
    group: (props) => (
      <GroupNode
        {...props}
        projectDetails={projectDetails}
        saveProjectDetailsObjects={saveProjectDetailsObjects}
        handleNodeTitleChange={handleNodeTitleChange}
      />
    ),
  }), [projectDetails, saveProjectDetailsObjects]);
  const wrapperRef = useRef(null);
  const [rfInstance, setRfInstance] = useState(null);
  const [threatFlow,setThreatFlow] = useState(projectDetails.threat_canvas)
  const [nodes, setNodes, onNodesChange] = useNodesState(
    projectDetails.threat_canvas.nodes ? projectDetails.threat_canvas.nodes : []
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState(
    projectDetails.threat_canvas.edges
      ? projectDetails.threat_canvas.edges
      : []
  );
  const { setViewport } = useReactFlow();
  const { screenToFlowPosition, getIntersectingNodes } = useReactFlow();
  const store = useStoreApi();
  const { nodeLookup } = store.getState();
  const onConnect = useCallback((connection) => {
    const edgeWithArrow = {
      ...connection,
      data: { label: "Dataflow" },
      type: "customEdge",
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
        color: "black",
      },
    };
    setEdges((eds) => addEdge(edgeWithArrow, eds));
    if (rfInstance) {
      setThreatFlow(rfInstance.toObject())
      updateProjectDetails({ ...projectDetails, threat_canvas: threatFlow });
    }
    setPageDirty(true);
  }, [setEdges, rfInstance]);

  const onDrop = useCallback((event) => {
    event.preventDefault();
    if (wrapperRef.current) {
      const wrapperBounds = wrapperRef.current.getBoundingClientRect();
      const jsonString = event.dataTransfer.getData("application/json");
      const data = JSON.parse(jsonString);
      console.log(data)
      const type = data.nodeType.type;
      let position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const nodeStyle =
        type === "group" ? { width: 300, height: 150 } : undefined;

      const intersections = getIntersectingNodes({
        x: position.x,
        y: position.y,
        width: 40,
        height: 40,
      }).filter((n) => n.type === "group");
      const groupNode = intersections[0];



      const newNode = {
        id: getId(),
        type,
        position,
        data:data.nodeType,
        style:nodeStyle
      };

      if (groupNode) {
        newNode.position = getNodePositionInsideParent(
          {
            position,
            width: 40,
            height: 40,
          },
          groupNode
        ) || { x: 0, y: 0 };
        newNode.parentId = groupNode.id;
        newNode.extent = groupNode ? "parent" : undefined;
        newNode.className = `group-node-${data.nodeType.key}`;
      }

      const currentNodes = store.getState().nodes; // Accessing nodes directly
      const sortedNodes = currentNodes.concat(newNode).sort(sortNodes);
      setNodes(sortedNodes);
      if(data.nodeType.sub_type === "project") {
        newNode.className = `group-node-${data.nodeType.key}`;
        setNodeIsProjectModal(true);
        setNodeIsProject(newNode);
        getAllProjects();
      }
      doThreatRecommendations(newNode);
    }
    setPageDirty(true);
  });

  const onNodeDragStop = useCallback(
    (_, node) => {
      if (node.type !== "node" && !node.parentId) {
        return;
      }

      const intersections = getIntersectingNodes(node).filter(
        (n) => n.type === "group"
      );
      const groupNode = intersections[0];

      if (intersections.length && node.parentId !== groupNode?.id) {
        const currentNodes  = store.getState().nodes; // Accessing nodes directly
        const nextNodes = 
        currentNodes
          .map((n) => {
            if (n.id === groupNode.id) {
              return {
                ...n,
                className: "",
              };
            } else if (n.id === node.id) {
              const position =
                getNodePositionInsideParent(n, groupNode) || {
                  x: 0,
                  y: 0,
                };

              return {
                ...n,
                position,
                parentId: groupNode.id,
                dragging: false,
                extent: "parent",
              };
            }

            return n;
          })
          .sort(sortNodes);

        setNodes(nextNodes);
      }
      setPageDirty(true);
    },
    [getIntersectingNodes, setNodes, store]
  );

  const onNodeDrag = useCallback(
    (_, node) => {
      if (node.type !== "node" && !node.parentId) {
        return;
      }

      const intersections = getIntersectingNodes(node).filter(
        (n) => n.type === "group"
      );
      const groupClassName =
        intersections.length && node.parentId !== intersections[0]?.id
          ? "active"
          : "";

      setNodes((nds) => {
        return nds.map((n) => {
          if (n.type === "group") {
            return {
              ...n,
              className: groupClassName,
            };
          } else if (n.id === node.id) {
            return {
              ...n,
              position: node.position,
            };
          }
          return { ...n };
        });
      });
    },
    [getIntersectingNodes, setNodes]
  );



  const handleNodeTitleChange = useCallback((nodeId, newNodeTitle) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
              ...node,
              data: {
                ...node.data,
                title: newNodeTitle,
              },
            }
          : node.type === "group" && node.id === nodeId // Check if the node is a group node
          ? {
              ...node,
              data: {
                ...node.data,
                title: newNodeTitle, // Modify the title of the group node
              },
            }
          : node
      )
    );
    setPageDirty(true);
  }, [setNodes]);
 
 const handleEdgeLabelChange = useCallback((edgeId, newEdgeLabel) => {
  setEdges((prevEdges) =>
    prevEdges.map((edge) =>
      edge.id === edgeId && edge.type === 'customEdge'
        ? {
            ...edge,
            data: { ...edge.data, label: newEdgeLabel },
          }
        : edge
    )
  );
  setPageDirty(true);
}, [setEdges]);

useEffect(() => {
  if (rfInstance) {
    setThreatFlow(rfInstance.toObject());
  }
  if (!isEqual(threatFlow, projectDetails.threat_canvas)) {
    updateProjectDetails({ ...projectDetails, threat_canvas: threatFlow });
  }
}, [edges,nodes,rfInstance]);

const handleReverseDirection = useCallback((edgeId) => {
  setEdges((prevEdges) =>
    prevEdges.map((edge) =>
      edge.id === edgeId && edge.type === 'customEdge'
      ? {
        ...edge,
        source: edge.target,
        sourceHandle: edge.targetHandle,
        target: edge.source,
        targetHandle: edge.sourceHandle,
        markerStart: {
          type: MarkerType.ArrowClosed,
          width: 0,
          height: 0,
          color: "black",
        },
      }
    : edge
    )
  );
}, [setEdges]);

const handleBidirection = useCallback((edgeId) => {
  setEdges((prevEdges) =>
    prevEdges.map((edge) =>
      edge.id === edgeId && edge.type === 'customEdge'
      ? {
        ...edge,
        markerStart: {
          type: MarkerType.ArrowClosed,
          width: 20,
          height: 20,
          color: "black",
        },
      }
    : edge
    )
  );
}, [setEdges]);

  return (
    <div className="wrapper">
      {projectDetails.project_status !== "review" && <Sidebar />}
      <div className="rfWrapper" ref={wrapperRef}>
        <ReactFlow
          connectionMode="loose"
          nodes={nodes}
          edges={edges}
          onEdgesChange={onEdgesChange}
          onNodesChange={onNodesChange}
          onConnect={onConnect}
          onNodeDrag={onNodeDrag}
          onNodeDragStop={onNodeDragStop}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onInit={setRfInstance}
          proOptions={proOptions}
          fitView
          selectNodesOnDrag={false}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
        >
          <Background
            id="1"
            gap={10}
            color="#f8f8f8"
            variant={BackgroundVariant.Lines}
          />
          <Background
            id="2"
            gap={100}
            offset={1}
            color="#f7f7f7"
            variant={BackgroundVariant.Lines}
          />
          <Controls />
          <Panel position="top-right">

          </Panel>
          <SelectedNodesToolbar />
        </ReactFlow>
      </div>

      <Modal
        title="Select existing service / project"
        open={nodeIsProjectModal}
        onOk={() => console.log('add project for this node')}
        okButtonProps={{type: "primary"}}
        onCancel={() => setNodeIsProjectModal(false)}
        width={1000}
      >
        <div className="form-field">
          {listOfProjects && listOfProjects.length > 0 &&
            <Select placeholder="Pick the service / project for this node" size="large" name="node_Project_Name">
              {listOfProjects.map((p) => {
                if(p.project_id !== projectDetails.project_id) {
                  return (<Select.Option key={p.project_id} value={p.project_id}>{p.project_title}</Select.Option>);
                }
              })}
            </Select>
          }
        </div>
                
                

                
      </Modal>


    </div>
  );
};

export default Canvas;