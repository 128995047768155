import React, { useState,useEffect } from 'react';
import axios from "axios";
import {
  Button,
  Input,
  Tooltip} from "antd";
import {
  ArrowRightOutlined,
  ArrowsAltOutlined,
  ArrowLeftOutlined,
  DeleteOutlined,
  MessageOutlined,
  SwapOutlined
} from '@ant-design/icons';
import {
  BaseEdge,
  getSmoothStepPath,
  EdgeLabelRenderer,
  useReactFlow,
  NodeToolbar
} from '@xyflow/react';
import CommentPopover from "../common/CommentPopover";
import MetadataPopover from "../common/MetadataPopover";
import API from "../../constants/api";
import { userDataStore } from '../../store/user';



const CustomEdge = (
  {
    id,
    data,
    projectDetails,
    saveProjectDetailsObjects,
    handleEdgeLabelChange,
    handleReverseDirection,
    handleBidirection,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    markerEnd,
    markerStart = {} 
  }) => {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX: sourceX,
    sourceY: sourceY,
    sourcePosition: sourcePosition,
    targetX: targetX,
    targetY: targetY,
    targetPosition: targetPosition,
  });

  const userData = userDataStore(state => state.userData);
  const [edgeLabel, setEdgeLabel] = useState(data.label);
  const [isEdgeEditing, setIsEdgeEditing] = useState(false);
  const [isEdgeClicked, setIsEdgeClicked] = useState(false);
  const [showCommentPopover, setShowCommentPopover] = useState(false);
  const [comments, setComments] = useState([]);
  const { deleteElements } = useReactFlow();
  const [selectedArrow, setSelectedArrow] = useState('right');

  useEffect(() => {
    setComments(projectDetails.comments || []);
  }, [projectDetails]);

  const handleLabelClick = () => {
    setIsEdgeEditing(true);
  };

  const handleEdgeClick = () => {
    setIsEdgeClicked(prevState => !prevState);
  };

  const handleInputChange = (e) => {
    const newEdgeLabel = e.target.value;
    setEdgeLabel(newEdgeLabel);
  };

  const handleInputBlur = () => {
    setIsEdgeEditing(false); 
    handleEdgeLabelChange(id, edgeLabel); 
  };

  const onDelete = () => deleteElements({ edges: [{ id }] });

  const handleReverseButton =()=>{
    handleReverseDirection(id)
    setSelectedArrow('left');
  }

  const handleBidirectionalArrow =  () => {
    handleBidirection(id)
    setSelectedArrow('bidirectional');
  }

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} markerStart={markerStart} />
      <EdgeLabelRenderer>
      {isEdgeClicked && (
        <div className='react-flow__node-toolbar nodrag nodeToolBar' style={{
          position: "absolute",
          top: labelY - 37,
          left: labelX-30,
          zIndex: "999",
          padding: "2px 5px",
          borderRadius: 3,
          pointerEvents: 'all',
        }}>
          <Tooltip title="Delete component">
            <Button onClick={onDelete} style={{ minWidth: "0.1rem", width: "0.9rem", height: "0.1rem", padding: '0px', marginRight: 0 }} shape="circle" icon={<DeleteOutlined style={{width:"0.5rem"}} />} />
          </Tooltip>
          <Tooltip title="Add a comment">
            <Button
              onClick={() => setShowCommentPopover(true)}
              style={{minWidth:"0.1rem",width:"0.9rem",height:"0.1rem",padding:'0px'}}
              shape="circle" 
              icon={<MessageOutlined style={{width:"0.5rem"}} />}
            />
          </Tooltip>
          
          <MetadataPopover
            id={id}
            projectDetails={projectDetails}
            componentType= "edge"
          />

          <Tooltip title="Bidirectional">
             <Button
               onClick={handleBidirectionalArrow}
               disabled={selectedArrow === 'bidirectional'}
               style={{minWidth:"0.1rem",width:"0.9rem",height:"0.1rem",padding:'0px',marginRight:0}}
               shape="circle"
               icon={<ArrowsAltOutlined style={{width:"0.5rem",height:"0.2rem", color: selectedArrow === 'bidirectional' ? 'blue' : 'black'}}/>}
             />
           </Tooltip>
           <Tooltip title="Left Arrow">
             <Button
               onClick={handleReverseButton}
               disabled={selectedArrow === 'left'}
               style={{minWidth:"0.1rem",width:"0.9rem",height:"0.1rem",padding:'0px',marginRight:0}}
               shape="circle"
               icon={<ArrowLeftOutlined style={{width:"0.5rem",height:"0.2rem",color: selectedArrow === 'left' ? 'blue' : 'black'}}/>}
             />
           </Tooltip>
           <Tooltip title="Right Arrow">
             <Button
               onClick={handleReverseButton}
               disabled={selectedArrow === 'right'}
               style={{minWidth:"0.1rem",width:"0.9rem",height:"0.1rem",padding:'0px',marginRight:0}}
               shape="circle"
               icon={<ArrowRightOutlined style={{width:"0.5rem",height:"0.2rem",color: selectedArrow === 'right' ? 'blue' : 'black'}}/>}
             />
           </Tooltip>
            <CommentPopover
              id={id}
              showCommentPopover={showCommentPopover}
              setShowCommentPopover={setShowCommentPopover}
              projectDetails={projectDetails}
              saveProjectDetailsObjects={saveProjectDetailsObjects}
              comments={comments}
              nodeText={edgeLabel}
            />
        </div>
      )}
        <div
          onClick={handleEdgeClick}
          onDoubleClick={handleLabelClick}
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          }}
          className="nodrag nopan canvasEdge"
        >
          {isEdgeEditing ? (
            <Input
              type="text"
              value={edgeLabel}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              autoFocus
              className="nodrag nopan edgeInput"
            />
          ) : (
            <span className="edgeLabel">{edgeLabel}</span>
          )}
        </div>    
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;