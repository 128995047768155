import { Route, Routes as BaseRoutes } from "react-router-dom";
import Home from "../modules/home/home";
import Projects from "../modules/projects/projects";
import ProjectDetails from "../modules/projectDetails/projectDetailsLayout";
import Templates from "../modules/templates/templates";
import Directory from "../modules/directory/directory";
import Landing from "../modules/landing/landing";
import Canvas from "../modules/canvas/canvas";
import Insights from "../modules/insights/insights";
import ThreatLibrary from "../modules/library/threatLibrary";
import ThreatCatalog from "../modules/library/catalog";
import OnboardUser from "../modules/auth/onboardUser";
import PageNotFound from "../modules/pages/PageNotFound";
import ThanksBetaUser from "../modules/pages/ThanksBetaUser";
import SecurityChecklist from "../modules/securityChecklist/securityChecklist";
import { ReactFlowProvider } from "@xyflow/react";
import Login from "../modules/auth/login";
import Logoff from "../modules/auth/logoff";
import ProjectReview from "../modules/projects/projectReview";
import Workspace from "../modules/workspace/workspace";

export default function Routes() {
  return (
    <ReactFlowProvider>
      <BaseRoutes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route
          path="/project/:view/:projectId"
          element={<ProjectDetails />}
        />
        <Route path="/templates" element={<Templates />} />
        <Route path="/directory" element={<Directory />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/canvas" element={<Canvas />} />
        <Route path="/insights" element={<Insights />} />
        <Route path="/onboarding" element={<OnboardUser />} />
        <Route path="/threat-library" element={<ThreatLibrary />} />
        <Route path="/threat-library/trusthive" element={<ThreatCatalog />} />
        <Route path="/security-checklist" element={<SecurityChecklist />} />
        <Route path="/workspace" element={<Workspace />} />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="/wrong-turn" element={<PageNotFound />} />
        <Route path="/thanks-beta-user" element={<ThanksBetaUser />} />

        <Route path="/login" element={<Login />} />
        <Route path="/logoff" element={<Logoff />} />

        {/* Admin/review flows */}
        <Route
          path="/project/details/review/:projectId"
          element={<ProjectReview />}
        />
      </BaseRoutes>
    </ReactFlowProvider>
  );
}
