import React, { useState } from 'react';
import { Link } from "react-router-dom";
import PageLayout from '../pageLayout/application';
import {
  Button,
  Card,
  Col,
  Row,
  List,
  Statistic,
  Typography
} from 'antd';

import {userDataStore} from '../../store/user';
import useDocumentTitle from '../../utils/pageTitle';
import { ReactComponent as InsightsWaiting } from '../../assets/insightsWaiting.svg';


const { Title } = Typography;

const CommunityHome = () => {
  useDocumentTitle("Home");
  const [collapsed, setCollapsed] = useState(false);
  const userData = userDataStore(state => state.userData);
  return (
  <PageLayout>
    <Row>
      <Col span={24}>
        {userData.data ? (
            <Title level={2}>
              Welcome to the Trusthive Community {userData.data.first_name}
            </Title>    
          ) : (
            <Title level={2}> Home </Title>    
          )
        }
      </Col>
    </Row>
  </PageLayout>
)};

export default CommunityHome;