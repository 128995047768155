//phases: development, cicd, production

const complianceChecklist = [
      {
        "category": "Data Privacy and Protection",
        "requirements": [
          {
            "type": "General Requirements",
            "tasks": [
              { "task": "Data Encryption", "description": "Encrypt all sensitive data in transit and at rest." },
              { "task": "Data Minimization", "description": "Collect only the data necessary for processing." },
              { "task": "Access Control", "description": "Implement strict access controls and role-based permissions for user data." },
              { "task": "Data Subject Rights", "description": "Enable users to access, modify, and delete their data as required by applicable regulations." },
              { "task": "Incident Response Plan", "description": "Establish a formal incident response plan for handling data breaches, including user notification procedures." },
              { "task": "Data Retention Policy", "description": "Implement policies for retaining and securely disposing of user data." }
            ]
          },
          {
            "type": "US-Specific Requirements",
            "tasks": [
              {
                "regulation": "CCPA",
                "tasks": [
                  { "task": "Right to Access", "description": "Allow California residents to access their data." },
                  { "task": "Right to Deletion", "description": "Provide the option for California residents to request deletion of their data." },
                  { "task": "Right to Opt-Out", "description": "Allow users to opt-out of the sale of their personal information." },
                  { "task": "Do Not Sell My Information Link", "description": "Include a link on the website for California residents to opt-out." },
                  { "task": "Data Breach Notifications", "description": "Comply with California data breach notification laws for incidents involving personal data." }
                ]
              },
              {
                "regulation": "GLBA",
                "tasks": [
                  { "task": "Data Security Program", "description": "Implement a written information security plan to protect customer data." },
                  { "task": "Safeguards Rule", "description": "Establish and maintain safeguards to ensure the security and confidentiality of customer records." }
                ]
              }
            ]
          },
          {
            "type": "EU-Specific Requirements",
            "isEU_Flag": true,
            "tasks": [
              {
                "regulation": "GDPR",
                "tasks": [
                  { "task": "Lawful Basis for Processing", "description": "Document a lawful basis for collecting and processing personal data." },
                  { "task": "Data Protection Officer", "description": "Appoint a DPO if required for large-scale data processing." },
                  { "task": "Data Processing Agreements", "description": "Set up DPAs with third-party processors to ensure GDPR compliance." },
                  { "task": "Data Breach Notifications", "description": "Notify the relevant data protection authority within 72 hours of a breach." },
                  { "task": "Right to Erasure", "description": "Enable users to request the deletion of their data." },
                  { "task": "Right to Portability", "description": "Allow users to download or transfer their personal data in a machine-readable format." }
                ]
              },
              {
                "regulation": "ePrivacy Directive",
                "tasks": [
                  { "task": "Cookies and Tracking Consent", "description": "Obtain user consent for cookies and tracking technologies." },
                  { "task": "Opt-Out Options", "description": "Provide a clear way for users to opt out of non-essential tracking." }
                ]
              }
            ]
          }
        ]
      },
      {
        "category": "Financial Compliance",
        "requirements": [
          {
            "type": "General Requirements",
            "tasks": [
              { "task": "PCI DSS Compliance", "description": "Ensure compliance with PCI DSS for handling credit card data." },
              { "task": "Secure Network", "description": "Maintain a secure network for processing credit card transactions." },
              { "task": "Cardholder Data Protection", "description": "Encrypt and securely store cardholder data." },
              { "task": "Access Control Measures", "description": "Restrict access to cardholder data." },
              { "task": "Regular Security Testing", "description": "Conduct vulnerability assessments and penetration testing." }
            ]
          },
          {
            "type": "US-Specific Requirements",
            "tasks": [
              {
                "regulation": "FTC",
                "tasks": [
                  { "task": "FTC Act Compliance", "description": "Avoid unfair or deceptive practices related to financial services." },
                  { "task": "Safeguards Rule", "description": "Establish a data protection program to secure customer information." }
                ]
              },
              {
                "regulation": "BSA/AML",
                "tasks": [
                  { "task": "Know Your Customer (KYC)", "description": "Implement KYC procedures to verify customer identities." },
                  { "task": "Suspicious Activity Monitoring", "description": "Monitor for and report suspicious transactions to authorities." }
                ]
              },
              {
                "regulation": "Regulation E",
                "tasks": [
                  { "task": "User Rights and Protections", "description": "Provide consumer rights to dispute unauthorized transactions and ensure refunds." },
                  { "task": "Disclosure Requirements", "description": "Inform users about fees, transaction limits, and error-resolution processes." }
                ]
              }
            ]
          },
          {
            "type": "EU-Specific Requirements",
            "isEU_Flag": true,
            "tasks": [
              {
                "regulation": "PSD2",
                "tasks": [
                  { "task": "Strong Customer Authentication", "description": "Enforce SCA for online payments using multi-factor authentication." },
                  { "task": "Open Banking Compliance", "description": "Enable secure API access to payment and account data if applicable." },
                  { "task": "Data Sharing with Third Parties", "description": "Allow user-approved access to payment account information." }
                ]
              },
              {
                "regulation": "AMLD",
                "tasks": [
                  { "task": "Customer Due Diligence", "description": "Implement KYC to verify identities and prevent money laundering." },
                  { "task": "Transaction Monitoring", "description": "Regularly monitor and report suspicious activities." },
                  { "task": "AML Record-Keeping", "description": "Maintain AML-related transaction records and audit trails for a minimum of 5 years." }
                ]
              }
            ]
          }
        ]
      },
      {
        "category": "Cybersecurity Compliance",
        "requirements": [
          {
            "type": "General Requirements",
            "tasks": [
              { "task": "Access Management", "description": "Use multi-factor authentication, role-based access control, and secure login practices." },
              { "task": "Network Security", "description": "Implement firewalls, intrusion detection/prevention systems, and regular vulnerability scans." },
              { "task": "Incident Response", "description": "Develop and test an incident response plan with defined roles and escalation paths." },
              { "task": "Data Backup and Recovery", "description": "Regularly back up critical data and test recovery procedures." },
              { "task": "Endpoint Security", "description": "Protect endpoint devices with antivirus software and regular updates." }
            ]
          },
          {
            "type": "US-Specific Requirements",
            "tasks": [
              {
                "regulation": "CISA",
                "tasks": [
                  { "task": "Critical Infrastructure Protection", "description": "Follow CISA guidelines if part of the critical infrastructure sector." },
                  { "task": "Cyber Incident Reporting", "description": "Report cybersecurity incidents to CISA for incidents impacting critical operations." }
                ]
              },
              {
                "regulation": "State Cybersecurity Laws",
                "tasks": [
                  { "task": "Risk Assessment", "description": "Conduct periodic risk assessments and implement appropriate security controls." },
                  { "task": "Cybersecurity Program", "description": "Maintain a written cybersecurity policy covering data protection, access controls, and incident response." }
                ]
              }
            ]
          },
          {
            "type": "EU-Specific Requirements",
            "isEU_Flag": true,
            "tasks": [
              {
                "regulation": "NIS Directive",
                "tasks": [
                  { "task": "Critical Infrastructure Security", "description": "Follow guidelines for securing network and information systems in essential services." },
                  { "task": "Cyber Incident Reporting", "description": "Report cyber incidents to national regulatory authorities in a timely manner." }
                ]
              },
              {
                "regulation": "Cybersecurity Act",
                "tasks": [
                  { "task": "Cybersecurity Certification", "description": "Obtain certification for certain ICT products and services if required." }
                ]
              }
            ]
          }
        ]
      }
];
  

export default complianceChecklist;
  