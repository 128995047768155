/**
 * TODO:
 * - first node on canvas never gets deleted
 * 
 */


import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import axios from "axios";
import {useParams} from 'react-router-dom';
import {
  Col,
  message,
  Menu,
  Row,
  Progress
} from 'antd';
// import PDL from '../pageLayout/pdl';
import ProjectNavBar from './components/projectNavbar';
import Canvas from '../canvas/canvas';
import API from "../../constants/api";
import { userDataStore } from "../../store/user";
import useDocumentTitle from '../../utils/pageTitle';
import Spacer from '../common/spacer';

const ProjectOverview = () => {
  const projectDetails ={project_id: 'aaa'};
  return (
    <>
      {projectDetails && projectDetails.project_id &&
        <>
          <Row>
            <Col span={16} offset={1}>
              <Spacer size={50} />
              <h2 className="project-page-header">Production & Release</h2>
              <Spacer size={20} />


              
              <div className="stats-blurb large">
                <Row>
                  <Col span={1}>
                    <Progress type="circle" percent={100} format={() => 'Done'} size="small" />
                  </Col>
                  <Col span={14} offset={1}>
                    <h4>Product Requirements</h4>
                    <p>All comments resolved</p>
                  </Col>
                </Row>
              </div>
              <div className="stats-blurb large">
                <Row>
                  <Col span={1}>
                    <Progress type="circle" percent={80} size="small" />
                  </Col>
                  <Col span={14} offset={1}>
                    <h4>Secure Design</h4>
                    <p>4 out of 20 threats are pending mitigation</p>
                  </Col>
                </Row>
              </div>
              <div className="stats-blurb large">
                <Row>
                  <Col span={1}>
                    <Progress type="circle" percent={40} size="small" />
                  </Col>
                  <Col span={14} offset={1}>
                    <h4>Security & Compliance Checklist</h4>
                    <p>15 out of 60 items pending implementation</p>
                  </Col>
                </Row>
              </div>
              <div className="stats-blurb large">
                <Row>
                  <Col span={1}>
                    <Progress type="circle" percent={35} size="small" />
                  </Col>
                  <Col span={14} offset={1}>
                    <h4>Vulnerabilities</h4>
                    <p>28 bugs and vuln tasks pending</p>
                  </Col>
                </Row>
              </div>
              


            </Col>
            <Col span={6} offset={1} className="drawer-col">
                {/* <CommentsDrawer
                  open={planCommentsDrawer}
                  onClose={() => setPlanCommentsDrawer(false)}
                  projectDetails={projectDetails}
                  updateProjectDetails={updateProjectDetails}
                  saveProjectDetails={console.log()}
                  saveProjectDetailsObjects={saveProjectDetailsObjects}
                />

                <div className="help-blurb" style={{margin: '50px 50px 0 0'}}>
                  <h3>
                    <Magic />
                    Let AI build your secure design
                  </h3>
                  <p>
                    Provide as much detailed requirements for this project and let our AI agent build out the threat models and 
                    action plan for you.
                  </p>
                  <p>
                    Include details like:
                    <ul>
                      <li>Functional requirements</li>
                      <li>Non-functional requirements</li>
                      <li>Infra structure requirements</li>
                      <li>Region requirements</li>
                      <li>and anything else</li>
                    </ul>
                  </p>
                </div> */}

            </Col>
          </Row>
        </>
      }
      
    </>
  );
};

export default ProjectOverview;