//phases: development, cicd, production

const securityChecklist = [
  {
    id: 0,
    title: 'User Device Security',
    description: '',
    items: [
        { "task": "Enforce Strong Authentication", "description": "Require strong passwords, support multi-factor authentication (MFA), and encourage users to secure their devices." },
        { "task": "Device Security Recommendations", "description": "Provide users with best practices for securing their devices (e.g., OS updates, screen lock)." },
        { "task": "App Security Checks", "description": "Detect rooted/jailbroken devices to prevent access from compromised devices." },
        { "task": "Biometric Authentication", "description": "Support biometric authentication where possible." }
    ],
    phase: 'development'
  },
  {
    id: 1,
    title: 'Web Mobile App Security',
    description: '',
    items: [
        { "task": "HTTPS Everywhere", "description": "Enforce HTTPS on all data transmitted between user devices and servers." },
        { "task": "Input Validation and Sanitization", "description": "Sanitize all inputs to protect against SQL Injection, XSS, and CSRF attacks." },
        { "task": "Secure Session Management", "description": "Set secure session cookies, enable HTTP-Only and Secure flags, and set session expiration." },
        { "task": "Content Security Policy (CSP)", "description": "Implement CSP to mitigate XSS attacks by controlling which scripts can be executed." },
        { "task": "Rate Limiting and CAPTCHA", "description": "Implement rate limiting and CAPTCHA on login and transaction endpoints to prevent automated attacks." }
    ],
    phase: 'development'
  },
  {
    id: 2,
    title: 'Backend Servers Security',
    description: '',
    items: [
        { "task": "Network Security", "description": "Use firewalls, virtual private clouds (VPCs), and network access control lists (ACLs) to restrict traffic." },
        { "task": "Access Controls", "description": "Implement role-based access control (RBAC) and enforce the principle of least privilege." },
        { "task": "Input Validation", "description": "Re-validate and sanitize inputs coming from the app to prevent injection attacks." },
        { "task": "Logging and Monitoring", "description": "Log all access, user actions, and server activity, and monitor logs for anomalies." },
        { "task": "Patch Management", "description": "Regularly update and patch servers and applications to fix known vulnerabilities." },
        { "task": "Automated Threat Detection", "description": "Implement automated alerts and anomaly detection tools to detect suspicious activity." }
    ],
    phase: 'development'
  },
  {
    id: 3,
    title: 'Payment Gateway Security',
    description: '',
    items: [
        { "task": "Secure API Integration", "description": "Use secure APIs (e.g., OAuth 2.0, OpenID Connect) for authentication with the payment gateway." },
        { "task": "Transaction Data Encryption", "description": "Encrypt transaction data both in transit and at rest within the payment gateway." },
        { "task": "Regular Audits", "description": "Conduct regular security audits with the payment gateway provider to ensure they follow compliance (e.g., PCI DSS)." }
    ],
    phase: 'development'
  },
  {
    id: 4,
    title: 'Database Security',
    description: '',
    items: [
        { "task": "Encryption at Rest", "description": "Encrypt sensitive data stored in the database using strong encryption algorithms (e.g., AES-256)." },
        { "task": "Access Restrictions", "description": "Restrict access to the database to authorized services and IPs only." },
        { "task": "Database Monitoring", "description": "Monitor database access logs and queries for unusual patterns (e.g., mass data access)." },
        { "task": "Regular Backups", "description": "Schedule regular backups of the database with encryption and secure storage." },
        { "task": "Data Retention Policy", "description": "Implement a policy to retain only necessary data and regularly purge old or unused data." }
    ],
    phase: 'development'
  },
  {
    id: 5,
    title: 'Notifications System Security',
    description: '',
    items: [
        { "task": "Avoid Sensitive Information", "description": "Exclude sensitive data (e.g., full account numbers) from SMS and email notifications." },
        { "task": "Encrypted Notifications", "description": "Use encrypted channels for notifications, especially for critical alerts (if possible)." },
        { "task": "Notification Logs", "description": "Log notification events to track sent messages and detect unusual activity." },
        { "task": "User Opt-In", "description": "Allow users to customize their notification preferences and only receive essential alerts." }
    ],
    phase: 'development'
  }
];

export default securityChecklist;
  