/**
 * TODO:
 * - first node on canvas never gets deleted
 * 
 */


import React, { useState, useEffect } from 'react';
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import axios from "axios";
import {useParams} from 'react-router-dom';
import {
  Avatar as AntAvatar,
  Button,
  message,
  notification
} from 'antd';
import CommentsDrawer from '../canvas/commentsDrawer';
import ThreatsDrawer from '../canvas/threatsDrawer';
import Canvas from '../canvas/canvas';
import API from "../../constants/api";
import { userDataStore } from "../../store/user";
import useDocumentTitle from '../../utils/pageTitle';
import { Robot } from 'react-bootstrap-icons';

const ProjectDesign = (propsData) => {
  const {
    projectDetails,
    updateProjectDetails,
    recommendedThreats,
    doThreatRecommendations,
    saveProjectDetailsObjects,
    designCommentsDrawer,
    setDesignCommentsDrawer,
    threatsDrawer,
    setThreatsDrawer,
    securityChecklistDrawer,
    setSecurityChecklistDrawer
  } = propsData;

  // useDocumentTitle("Project Details");
  const isEU_Flag = projectDetails.meta_data && projectDetails.meta_data.is_EU ? true : false;
  // const [messageApi, contextHolder] = message.useMessage();
  const [isProjectSaving, setIsProjectSaving] = useState(false);
  const [isPageDirty, setPageDirty] = useState(false);
  const userData = userDataStore((state) => state.userData);
  const {projectId} = useParams();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = () => {
    api.open({
      description:(<>
        <div className="bot-message-blurb">
          <AntAvatar src={<Robot />} className="bot-avatar"/>
          <div className="message">
              <h4>SA Agent</h4>
              <p style={{background: 'transparent', padding: 0}}>
                Project details have been updated with a new <strong>Region: EU</strong>.<br />
                This will impact a few of your components due to the added regulations and compliance.<br />
                Please address the affected components before you can proceed.
              </p>
          </div>
          <div className="button-bar">
            <Button color="primary" variant="link">
              OK
            </Button>
            <Button color="primary" variant="outlined">
              Fix it for me
            </Button>
          </div>
        </div>
      </>),
      // showProgress: true,
      pauseOnHover: true,
      className: 'error-notification'
    });
  };

  useEffect(() => {
    if(isEU_Flag) {
      openNotification();
    }
  }, []);
  
  return (
    <>
      {projectDetails && projectDetails.project_id &&
        <div className="project-page-wrapper">
          {contextHolder}
            {projectDetails && projectDetails.threat_canvas &&
              <Canvas
                projectDetails={projectDetails}
                updateProjectDetails={updateProjectDetails}
                doThreatRecommendations={doThreatRecommendations}
                saveProjectDetailsObjects={saveProjectDetailsObjects}
                setPageDirty={setPageDirty}
              />
            }
            <CommentsDrawer
              open={designCommentsDrawer}
              onClose={() => setDesignCommentsDrawer(false)}
              projectDetails={projectDetails}
              updateProjectDetails={updateProjectDetails}
              saveProjectDetailsObjects={saveProjectDetailsObjects}
            />

            <ThreatsDrawer
              open={threatsDrawer}
              onClose={() => setThreatsDrawer(false)}
              projectDetails={projectDetails}
              recommendedThreats={recommendedThreats}
              saveProjectDetailsObjects={saveProjectDetailsObjects}
            />



        </div>
      }
      
    </>
  );
};

export default ProjectDesign;