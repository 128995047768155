import React, { useState, useEffect } from "react";
import { ReactComponent as EmptySpaceSVG } from '../../assets/space.svg';


const EmptySpace = (data) => {
    const {title} = data;
    return (
        <div style={{textAlign: 'center'}}>
            <EmptySpaceSVG style={{width:250, height: 250}}/>
            <p>{title}</p>
        </div>
    );
};

export default EmptySpace;
