/**
 * TODO:
 * - Add filters
 * - Threat actors not displaying
 * 
 */

import React, { useState, useEffect } from "react";
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import pullAt from 'lodash/pullAt';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import { 
  Alert,
  Button,
  Col,
  Collapse,
  Drawer,
  Empty,
  Input,
  Modal,
  Row,
  Segmented,
  Tag,
  Tooltip,
  Typography
} from "antd";
import {
  Stars,
  ShieldFillCheck,
} from 'react-bootstrap-icons';
import API from "../../constants/api";
import { userDataStore } from "../../store/user";
import { 
  CheckOutlined,
  DeleteOutlined,
  MinusOutlined,
  PlusOutlined,
  ThunderboltOutlined 
} from "@ant-design/icons";
import { THREAT_RISK, getThreatRisk } from '../../constants/threatRisk';
import ThreatForm from "../common/ThreatForm";
import Spacer from '../common/spacer';
import { getThreatCategory } from '../../constants/threatCategories';

const { Panel } = Collapse;
const { Title } = Typography;
const { TextArea } = Input;

const ThreatTag = ({ threat }) => {
  const r = getThreatRisk(threat.risk);

  return (
    <>
      {threat.risk &&
        <Tooltip title={r.id}>
          <Tag color={r.color}>
            {r.id}
          </Tag>
        </Tooltip>
      }
    </>
  );
}

const ThreatDetails = ({ threat }) => {
  return (
    <>
      <div className='threatBlurb'>
        <div>
          {threat.threat_categories &&
            <>
              <label>Categories</label>
              {threat.threat_categories.map((cat, index) => (
                <Tag key={index}>{getThreatCategory(cat)}</Tag>
              ))}
            </>}
        </div>

        <Spacer size={25} />

        <div>
          {threat.tags &&
            <>
              <label>Tags</label>
              {threat.tags.map((tag, index) => (
                <Tag key={index}>{tag}</Tag>
              ))}
            </>}
        </div>

        <Spacer size={25} />

        <div>
          <label>Description</label>
          <p>{threat.threat_description}</p>
        </div>

        <Spacer size={25} />

        <div>
          <label>Affected components</label>
          {threat.components &&
            <>
              {threat.components.map((item, index) => (
                <p key={index}>- {item}</p>
              ))}
            </>
          }
        </div>

        <Spacer size={25} />

        <div>
          {threat.mitigation_list &&
            <>
              <label>Mitigation list</label>
              {Array.isArray(threat.mitigation_list) ?
                <>
                  {threat.mitigation_list.map((item, index) => (
                    <p key={index}>- {item.title}</p>
                  ))}
                </> :
                <>
                  {threat.mitigation_list}
                </>
              }
            </>
          }
        </div>
        
        <Spacer size={25} />

        {threat.comment && 
          <div style={{background: '#f1f1f1', padding: 10, borderRadius: 5}}>
            <label>Mitigation comments</label>
            <p>{threat.comment.message.body}</p>
          </div>
        }

      </div>
    </>
  )
}

const ThreatPanel = ({
  threat,
  index,
  threatView,
  addRecommendedThreatToProject,
  ignoreRecommendedThreatFromProject,
  setMitigateThreatModalOpen,
  setThreatBeingMitigated,
  setDeleteThreatModalOpen,
  setThreatBeingDeleted
}) => {
  return (
    <div key={index}>
      {(threat.status !== "ADDED_TO_PROJECT" && threat.status !== "IGNORE_FROM_PROJECT") &&
        <div className={`threat-card ${threat.risk}`} key={index}>
          <div className="threat-card-header">
            <div className="threat-title">{threat.threat_title}</div>
          </div>

          <div className="threat-card-info">
            <Row gutter={20}>
              <Col span={4}>
                <div className="blob divider">
                  <h6>RISK</h6>
                  <p><ThreatTag threat={threat} /></p>
                </div>
              </Col>
              <Col span={5}>
                <div className="blob divider">
                  <h6>STATUS</h6>
                  <p>
                    {threat.status === "MITIGATED" ?
                      <Tag className="pill green">{threat.status}</Tag> :
                      <Tag className="pill">OPEN</Tag>
                    }
                  </p>
                </div>
              </Col>
              {threatView !== "RECOMMENDED_THREATS" &&
                <Col span={7}>
                  <div className="blob divider">
                    <h6>CREATED BY</h6>
                    <p>John Doe</p>
                  </div>
                </Col>
              }
              <Col span={8}>
                <div className="blob">
                  <h6>CATEGORY</h6>
                  <p>
                  {threat.threat_categories.map((cat, index) => (
                    `${getThreatCategory(cat)}`
                  ))}
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="threat-card-details">
            <Collapse
              collapsible="header"
              key={index}
              expandIconPosition="start"
            >
              <Panel
                key={threat.key || index}
                header={
                  <Row>
                    <Col span={4} offset={20} style={{ textAlign: 'right' }}>
                      {threatView === "RECOMMENDED_THREATS" ?
                        <>
                          <Tooltip title="Add threat to the project">
                            <Button shape="circle" type="primary" className="btn-icon-outlined" onClick={(e) => {e.stopPropagation(); addRecommendedThreatToProject(threat)}}>
                              <PlusOutlined />
                            </Button>
                          </Tooltip>&nbsp;&nbsp;&nbsp;
                          <Tooltip title="Ignore this threat">
                            <Button shape="circle" onClick={(e) => {e.stopPropagation(); ignoreRecommendedThreatFromProject(threat)}}><MinusOutlined /></Button>
                          </Tooltip>
                        </> :
                        <>
                          <Tooltip title="Mark this threat resolved">
                            <Button type="primary" shape="circle" className="btn-icon-outlined" onClick={(e) => {e.stopPropagation(); setMitigateThreatModalOpen(true); setThreatBeingMitigated(threat);}}>
                              <CheckOutlined />
                            </Button>
                          </Tooltip>&nbsp;&nbsp;&nbsp;
                          <Tooltip title="Remove this threat from project">
                            <Button shape="circle" onClick={(e) => {e.stopPropagation(); setDeleteThreatModalOpen(true); setThreatBeingDeleted(threat)}}><DeleteOutlined /></Button>
                          </Tooltip>
                        </>
                      }
                    </Col>
                  </Row>
                }
              >
                <ThreatDetails threat={threat} />
              </Panel>
            </Collapse>
          </div>
        </div>
      }
    </div>
  );
};

const ThreatsDrawer = ({
  onClose,
  open,
  projectDetails,
  recommendedThreats,
  saveProjectDetailsObjects
}) => {
  const userData = userDataStore((state) => state.userData);
  const [threats, setThreats] = useState([]);
  const [threatView, setThreatView] = useState("PROJECT_THREATS"); // ["PROJECT_THREATS", "ADD_THREAT", "RECOMMENDED_THREATS"]
  const [riskFilter, setRiskFilter] = useState([]);
  const [isMitigateThreatModalOpen, setMitigateThreatModalOpen] = useState(false);
  const [isDeleteThreatModalOpen, setDeleteThreatModalOpen] = useState(false);
  const [threatBeingMitigated, setThreatBeingMitigated] = useState({});
  const [threatBeingDeleted, setThreatBeingDeleted] = useState({});
  const [threatComment, setThreatComment] = useState("");

  useEffect(() => {
    setThreats(projectDetails.threats || []);
    setThreatView("PROJECT_THREATS");
  }, [projectDetails]);

  const addRecommendedThreatToProject = (threat) => {
    newThreatSubmitted(threat);
    find(recommendedThreats, { threat_id: threat.threat_id }).status = "ADDED_TO_PROJECT";
    saveProjectDetailsObjects("recommended_threats", recommendedThreats);
  }

  const ignoreRecommendedThreatFromProject = (threat) => {
    find(recommendedThreats, { threat_id: threat.threat_id }).status = "IGNORE_FROM_PROJECT";
    saveProjectDetailsObjects("recommended_threats", recommendedThreats);
  }

  const mitigateThreat = async () => {
    const {threats, comments} = projectDetails;
    const commentObj = {
      "user": {
        "user_id": userData.data.user_id,
        "full_name": userData.data.full_name,
        "picture": userData.data.picture
      },
      "message": {
          "body": threatComment
      },
      "created_date": new Date().toUTCString(),
      "threat": {
        "id": threatBeingMitigated.threat_id,
        "title": threatBeingMitigated.threat_title
      },
    }
    comments.push(commentObj);
    find(threats, { threat_id: threatBeingMitigated.threat_id }).comment = commentObj;
    find(threats, { threat_id: threatBeingMitigated.threat_id }).status = "MITIGATED";
    await saveProjectDetailsObjects("threats", threats);
    await saveProjectDetailsObjects("comments", comments);
    setMitigateThreatModalOpen(false);
  }

  const deleteThreat = async () => {
    const {threats, comments} = projectDetails;
    const commentObj = {
      "user": {
        "user_id": userData.data.user_id,
        "full_name": userData.data.full_name,
        "picture": userData.data.picture
      },
      "message": {
          "body": threatComment
      },
      "created_date": new Date().toUTCString(),
      "threat": {
        "id": threatBeingDeleted.threat_id,
        "title": threatBeingDeleted.threat_title,
        "action": "deleted"
      },
    }
    comments.push(commentObj);
    let index = findIndex(threats, { threat_id: threatBeingDeleted.threat_id });
    if (index !== -1) {
      pullAt(threats, index);
    }
    await saveProjectDetailsObjects("threats", threats);
    await saveProjectDetailsObjects("comments", comments);
    setDeleteThreatModalOpen(false);
  }

  const newThreatSubmitted = (obj) => {
    obj.threat_id = uuidv4();
    const updatedThreats = [...threats, obj];
    setThreats(updatedThreats);
    saveProjectDetailsObjects("threats", updatedThreats);
  }

  const handleRiskFilterChange = (value) => {
    setRiskFilter(value);
  }

  const filteredThreats = threats.filter(threat => riskFilter.length === 0 || riskFilter.includes(threat.risk));

  return (
    <Drawer
      title="Threats"
      placement="right"
      onClose={() => { setThreatView("PROJECT_THREATS"); onClose(); }}
      open={open}
      width={500}
      mask={false}
      getContainer={false}
    >
      {threatView === "ADD_THREAT" &&
        <ThreatForm 
          exitThreatForm={() => setThreatView("PROJECT_THREATS")}
          onNewThreatSubmit={newThreatSubmitted}
          addThreatToProject={true}
          addThreatToOrg={true}
        />
      }

      {threatView === "PROJECT_THREATS" &&
        <>
          <Row style={{marginBottom: 25}}>
            <Col span={13}>
              <label>
                <strong>Risk</strong>
              </label>
              <Segmented
                options={["All", "High", "Medium", "Low"]}
                onChange={handleRiskFilterChange}
                style={{marginLeft: 10}}
              />
            </Col>
            <Col span={5} offset={6}>
              <Button className="btn-outlined" onClick={() => setThreatView("ADD_THREAT")} style={{marginTop: 0}}>Add Threat</Button>
            </Col>
          </Row>

          {recommendedThreats.filter(t => !t.status).length > 0 &&
            <div className="recommendedThreats">
              <h5>
                <Stars />&nbsp;
                Recommended threats
              </h5>
              <h6>
                Based on the components used in your flow, we found <strong>{recommendedThreats.filter(t => !t.status).length} threats</strong>.
              </h6>
              <Button className="btn-outlined" onClick={() => setThreatView("RECOMMENDED_THREATS")}>Click here to explore</Button>&nbsp;&nbsp;
            </div>
          }
          <Spacer size={25} />
          {filteredThreats.length ?
            <>
              {filteredThreats.map((threat, index) => (
                <ThreatPanel
                  key={index}
                  threat={threat}
                  index={index}
                  threatView={threatView}
                  setMitigateThreatModalOpen = {setMitigateThreatModalOpen}
                  setThreatBeingMitigated = {setThreatBeingMitigated}
                  setDeleteThreatModalOpen = {setDeleteThreatModalOpen}
                  setThreatBeingDeleted = {setThreatBeingDeleted}
                />
              ))}
            </> : 
            <div className="empty-state">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No threats detected. Add components or click on Add threats." />
            </div>
          }
        </>
      }

      {threatView === "RECOMMENDED_THREATS" &&
        <>
          <div className="recommendedThreats">
            <h5>
              <Stars />&nbsp;
              Recommended threats
            </h5>
            <h6>
              Based on the components used in your flow, we found <strong>{recommendedThreats.filter(t => !t.status).length} threats</strong>.
            </h6>
          </div>
          <Spacer size={25} />
          {recommendedThreats.filter(t => !t.status).map((threat, index) => (
            <ThreatPanel
              key={index}
              threat={threat}
              index={index}
              threatView={threatView}
              addRecommendedThreatToProject={addRecommendedThreatToProject}
              ignoreRecommendedThreatFromProject={ignoreRecommendedThreatFromProject}
            />
          ))}
        </>
      }

      <Modal
        title={<>
          <ShieldFillCheck style={{color: 'green'}} /> 
            Mark Threat done
          </>}
        open={isMitigateThreatModalOpen}
        onOk={mitigateThreat}
        onCancel={() => setMitigateThreatModalOpen(false)}
        okText="Done"
        okButtonProps={{ className: 'btn-success' }}
        width={700}
      >
        <div className="form-field">
          <Alert message="Enter the details for the steps being taken to mitigate this threat." type="info" showIcon />
          <Spacer size={25} />
          <div className="threat-card-details">
            <h3>{threatBeingMitigated.threat_title}</h3>
            <div style={{ maxHeight: 200, overflowY: 'auto'}}>
              <ThreatDetails threat={threatBeingMitigated} />
            </div>
          </div>
          <Spacer size={50} />
          <div className="form-field">
            <label>Comment</label>
            <TextArea placeholder="Type your comment" autoSize={{ minRows: 3, maxRows: 6}} value={threatComment} onChange={(e) => {setThreatComment(e.target.value)}} />
          </div>
        </div>
      </Modal>

      <Modal
        title={<><ShieldFillCheck style={{color: '#ff4d4f'}} /> Remove this threat from project? </>}
        open={isDeleteThreatModalOpen}
        onOk={deleteThreat}
        onCancel={() => setDeleteThreatModalOpen(false)}
        okText="Done"
        okButtonProps={{
          type: "primary",
          danger: true,
        }}
        className="danger-modal"
        width={700}
      >
        <Alert message="You are about to delete an already added threat from this project. Kindly provide more details." type="error" showIcon />
        <Spacer size={25} />
        <div className="threat-card-details">
          <h3>{threatBeingMitigated.threat_title}</h3>
          <div style={{ maxHeight: 200, overflowY: 'auto'}}>
            <ThreatDetails threat={threatBeingDeleted} />
          </div>
        </div>
        <Spacer size={50} />
        <div className="form-field">
          <label>Comment</label>
          <TextArea placeholder="Type your comment" autoSize={{ minRows: 3, maxRows: 6}} value={threatComment} onChange={(e) => {setThreatComment(e.target.value)}} />
        </div>
      </Modal>

    </Drawer>
  );
};

export default ThreatsDrawer;
