import axios from 'axios';
import API from '../constants/api';
import { PROJECT_STATUS } from "../constants/app";
import { Archive, Cloud, Code, Exclamation, ExclamationCircle, ExclamationDiamond, ExclamationSquare, ExclamationTriangle, Key, Layers, Stack } from 'react-bootstrap-icons';

const getBadgeColor = (status) => {
    switch (status) {
        case PROJECT_STATUS.DRAFT:
          return "geekblue";
        case PROJECT_STATUS.SECURITY_APPROVED:
          return "purple";
        case PROJECT_STATUS.PENDING_REVIEW:
          return "warning";
        case PROJECT_STATUS.PRODUCTION:
          return "green";
        default:
          return "default"; // Use a default color for other statuses
      }
}

const addThreatTagToOrganization = (tag, organization_id, access_token) => {
  axios.post(
    API.CREATE_THREAT_TAG,
    {
      label: tag,
      organization_id: organization_id
    },
    {
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
  .then((response) => {
    console.log('tag created');
  })
  .catch((err) => {
    console.log("Error creating tag:", err);
  });
}

const addMetadataTagToOrganization = (tag,organization_id, access_token) => {
  axios.post(
    API.CREATE_COMPONENT_METADATA_TAG,
    {
      label: tag,
      organization_id: organization_id
    },
    {
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
  .then((response) => {
    console.log('Metadata tag created');
  })
  .catch((err) => {
    console.log("Error creating tag:", err);
  });
}

const findAndUpdateByAttribute = (obj, attributeName, attributeValue, updatedValues) => {
  // Check if the current object matches the search criteria
  if (obj && obj[attributeName] === attributeValue) {
      // Update the matched object with the provided values
      Object.assign(obj, updatedValues);
      return true; // Return true to indicate the object was found and updated
  }

  // If the current object is not a match, recursively search its children
  for (let key in obj) {
      if (typeof obj[key] === 'object') {
          // If a match is found in any child object, return true
          if (findAndUpdateByAttribute(obj[key], attributeName, attributeValue, updatedValues)) {
              return true;
          }
      }
  }

  // Return false if no matching object was found
  return false;
}

const isUserAuthorized = (userData) => {
  let f = false;
  if(userData && userData.data) {
    const { organization_id, onboarded } = userData.data ? userData.data : {};
    if(organization_id !== undefined && onboarded) {
      f = true;
    }
  }
  return f;
}

const isCommunityEdition = (userData) => {
  let f = false;
  if(userData && userData.data) {
    const { organization_id } = userData.data ? userData.data : {};
    if(organization_id === "community") {
      f = true;
    }
  }
  return f;
}

const getIssueIcon = (i) => {
  switch (i) {
      case 'cloud':
        return <Cloud />;
      case 'sast':
        return <Code />;
      case 'malware':
        return <Layers />;
        case 'open_source':
          return <Layers />;
      case 'surface_monitoring':
        return <Stack />;
      case 'leaked_secret':
        return <Key />;
      default:
        return <Exclamation />; // Use a default color for other statuses
    }
}

const getIssueSeverity = (s) => {
  if(s >= 90) {
    return <div className='issue-severity-critical'><ExclamationTriangle /> <span>Critical</span></div>;
  } else if(s >= 80) {
    return <div className='issue-severity-high'><ExclamationCircle /> <span>High</span></div>;
  } else if(s >= 60) {
    return <div className='issue-severity-medium'><ExclamationDiamond /> <span>Medium</span></div>;
  } else {
    return <div className='issue-severity-low'><ExclamationSquare /> <span>Low</span></div>;
  }
}

export {
  getBadgeColor,
  addThreatTagToOrganization,
  addMetadataTagToOrganization,
  findAndUpdateByAttribute,
  isUserAuthorized,
  isCommunityEdition,
  getIssueIcon,
  getIssueSeverity
};