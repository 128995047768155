/**
 * TODO
 * - CLicking on component name in comment should open the relevant popover
 * - @mention users
 */

import React, { useState } from 'react';
import find from "lodash/find";
import startsWith from "lodash/startsWith";
import axios from "axios";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Dropdown,
  Drawer,
  Empty,
  Input,
  List,
  Row,
  Space,
  Tabs,
  Tag
} from 'antd';
import moment from 'moment';
import { userDataStore } from "../../../store/user";
import { getIssueIcon, getIssueSeverity } from '../../../utils/utils';
import Spacer from '../../common/spacer';
import { BellSlash, Bug, ChevronDown, EyeSlash, Robot } from 'react-bootstrap-icons';
import EmptySpace from '../../common/EmptySpace';

const { TextArea } = Input;
const { TabPane } = Tabs;
const handleMenuClick = (e) => {
  console.log('click', e);
};
const issueActions = [
  {
    label: 'Fix the issue for me',
    key: '1',
    icon: <Robot />,
  },
  {
    label: 'Create a task',
    key: '2',
    icon: <Bug />,
  },
  {
    label: 'Snooze this issue',
    key: '3',
    icon: <BellSlash />
  },
  {
    label: 'Ignore this issue',
    key: '4',
    icon: <EyeSlash />
  },
];
const issueActionsProps = {
  issueActions,
  onClick: handleMenuClick
};

const IssueDetailsDrawer = ({
  open,
  onClose, 
  issueDetails
}) => {
  const userData = userDataStore((state) => state.userData);

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      open={open}
      mask={false}
      size="large"
      className="issue-details-drawer"
    >
      {issueDetails.id &&
        <>
          <div className="snapshot">
            <Row>
              <Col span={2}>
                <div className='issue-severity'>
                  {getIssueSeverity(issueDetails.trusthive_priority)}
                </div>
              </Col>
              <Col span={15} offset={2}>
                <h3>{issueDetails.package_name}</h3>
                <p>We found {issueDetails.issues.length} issues </p>
                <p>
                  <Tag color='red'>{getIssueIcon(issueDetails.type)} Miscounfiguration</Tag>
                </p>
              </Col>
              <Col span={4} offset={1}>
                <Dropdown menu={{ items: issueActions }} trigger={['click']}>
                    <Button onClick={(e) => e.preventDefault()} color="primary" variant="outlined">
                        Actions
                        <ChevronDown />
                    </Button>
                </Dropdown>
              </Col>
            </Row>
            <Spacer size={25} />
            <Tabs defaultActiveKey = "issueDetailsOverview">
              <TabPane tab="Overview" key="issueDetailsOverview">
                <h4>Summary</h4>
                <p>
                  {issueDetails.issues[0].description}
                </p>
                <Spacer size={20} />
                <h4>How do I fix it?</h4>
                <p>
                  {issueDetails.issues[0].remediation}
                </p>
              </TabPane>
              <TabPane tab="Tasks" key="issueDetailsTasks">
                <EmptySpace title="No tasks found"/>
              </TabPane>
              <TabPane tab="Comments" key="issueDetailsComments">
                comments
              </TabPane>
            </Tabs>
            

          </div> 
        </>
      }
    </Drawer>
  );
};
export default IssueDetailsDrawer;