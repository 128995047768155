/**
 * Refactor Avatar
 */

import React from "react";
import { ReactComponent as VantaLogo } from '../../assets/Vanta_logo.svg';
import {
    Button
} from 'antd';


const BrandBtn = (data) => {
    const {brand, clickFn, preTxt, postTxt} = data;
    return (
        <>
            <Button className={`brand-btn ${brand}`}>
                {preTxt}
                {brand === 'vanta' &&
                    <VantaLogo />
                }
                {postTxt}
            </Button>
        </>
    )
};

export default BrandBtn;