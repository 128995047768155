import React, { useState } from 'react';
import EnterpriseHome from './enterprise_home';
import CommunityHome from './community_home';
import {userDataStore} from '../../store/user';
import useDocumentTitle from '../../utils/pageTitle';

const Home = () => {
  useDocumentTitle("Home");
  const userData = userDataStore(state => state.userData);
  return (
  <>
    {userData && userData.data && userData.data.organization_id === "community" ? 
      <CommunityHome /> : <EnterpriseHome />
    }
  </>
)};

export default Home;