/**
 * TODO:
 * - first node on canvas never gets deleted
 * 
 */


import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import axios from "axios";
import {useParams} from 'react-router-dom';
import {
  Col,
  message,
  Menu,
  Row
} from 'antd';
// import PDL from '../pageLayout/pdl';
import ProjectNavBar from './components/projectNavbar';
import Canvas from '../canvas/canvas';
import API from "../../constants/api";
import { userDataStore } from "../../store/user";
import useDocumentTitle from '../../utils/pageTitle';

const ProjectManage = () => {
  const projectDetails ={project_id: 'aaa'};
  return (
    <>
      {projectDetails && projectDetails.project_id ? 
        <>
            ProjectManage
        </> : 
        <>
        </>
      }
      
    </>
  );
};

export default ProjectManage;