/**
 * TODO:
 * - first node on canvas never gets deleted
 * 
 */


import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from "react-router-dom";
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import axios from "axios";
import {useParams} from 'react-router-dom';
import {
  Button,
  Col,
  Dropdown,
  Input,
  Row,
  Space,
  Table
} from 'antd';
import ProjectNavBar from './components/projectNavbar';
// import API from "../../constants/api";
import { userDataStore } from "../../store/user";
import useDocumentTitle from '../../utils/pageTitle';
import issues from '../../data/issues';
import { Archive, Box, BracesAsterisk, Bug, ChevronDown, CircleFill, Cloud, Code, Dot, ExclamationDiamond, Globe2, Key, Layers, Search, Stack } from 'react-bootstrap-icons';
import Spacer from '../common/spacer';
import { getIssueIcon, getIssueSeverity } from '../../utils/utils';
import IssueDetailsDrawer from './components/issueDetailsDrawer';

const { Column } = Table;

const artefactOptions = [
  {
    key: '0',
    label: 'Open Source Dependencies',
    icon: <Archive />
  },
  {
    key: '1',
    label: 'SAST',
    icon: <Code />
  },
  {
    key: '2',
    label: 'Infrastructure-as-code',
    icon: <BracesAsterisk />
  },
  {
    key: '3',
    label: 'Exposed Secrets',
    icon: <Key />
  },
  {
    key: '4',
    label: 'DAST',
    icon: <Layers />
  },
  {
    key: '5',
    label: 'Container Images',
    icon: <Stack />
  },
  {
    key: '6',
    label: 'Cloud Configurations',
    icon: <Cloud />
  },
];

const artefactOptionsProps = {
  artefactOptions,
  // onClick: handleMenuClick,
  selectable: true,
};

const ProjectBuild = (propsData) => {
  const {
    projectDetails
  } = propsData;

  const [ issueDetailsDrawer, setIssueDetailsDrawer] = useState(false);
  const [issueDetailsView, setIssueDetailsView] = useState({});

  return (
    <div className="project-details-page-wrapper">
        <div className="page-header-bar">
          <Row>
            <Col span={8}>
              <h3>Build & deploy</h3>
            </Col>
            <Col span={10} offset={6} style={{textAlign: 'right', verticalAlign: 'middle'}}>
              <Space size={25}>
                <Input placeholder="search" prefix={<Search />} style={{width: '400px'}} />

                <Dropdown menu={{items: artefactOptions}} trigger={['click']}>
                  <Button>
                      <Space>
                        All artefact types
                        <ChevronDown style={{verticalAlign: 'middle'}}/>
                      </Space>
                  </Button>
                </Dropdown>
                <Button>
                    <Space>
                      Actions
                      <ChevronDown style={{verticalAlign: 'middle'}}/>
                    </Space>
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        
        <Row gutter={40}>
          <Col span={6}>
            <div className="stats-blurb">
              <Row>
                <Col span={3}>
                  <Bug className="icon bug"/>
                </Col>
                <Col span={20} offset={1}>
                  <h4>Issues</h4>
                  <p><CircleFill className="inline-icon critical" /> 40 critical</p>
                  <p><CircleFill className="inline-icon high"/> 20 high</p>
                  <p><CircleFill className="inline-icon medium"/> 120 medium</p>
                  <p><CircleFill className="inline-icon low"/> 4 low</p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={6}>
            <div className="stats-blurb">
              <Row>
                <Col span={3}>
                  <Box className="icon artefact"/>
                </Col>
                <Col span={20} offset={1}>
                  <h4>Issue types</h4>
                  <p><CircleFill className="inline-icon" />7 artefact types</p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={6}>
            <div className="stats-blurb">
              <Row>
                <Col span={3}>
                  <ExclamationDiamond className="icon risk"/>
                </Col>
                <Col span={20} offset={1}>
                  <h4>Risk</h4>
                  <p><CircleFill className="inline-icon" /> 3 services</p>
                  <p><CircleFill className="inline-icon"/> 11 components</p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={6}>
            <div className="stats-blurb">
              <Row>
                <Col span={3}>
                  <Globe2 className="icon env" />
                </Col>
                <Col span={20} offset={1}>
                  <h4>Environments</h4>
                  <p><CircleFill className="inline-icon" /> 2 Dev instances</p>
                  <p><CircleFill className="inline-icon"/> 3 Stage instances</p>
                  <p><CircleFill className="inline-icon"/> 3 Prod instances</p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        
        <Spacer size={25} />

        <Table
          rowKey="id"
          dataSource={issues}
          bordered
          style={{background: '#FFFFFF;'}}
          rowClassName={(r, index) => r.id === issueDetailsView.id ? 'highlight-issue-row' :  'issue-row'}
        >
        <Column
          title="Type"
          className="issue-type"
          dataIndex="type"
          key="type"
          render={(x, data) => {
            return <span style={{fontSize: 15}}>{getIssueIcon(data.type)}</span>;
          }}
          width={40}
        />

        <Column
          title="Name"
          dataIndex="name"
          key="name"
          render={(x, data) => {
            return <Button type='link' onClick={() => {setIssueDetailsView(data); setIssueDetailsDrawer(true);}}>{data.package_name}</Button>;
          }}
        />

        <Column
          width={120}
          title="Severity"
          dataIndex="severity"
          key="severity"
          render={(x, data) => {
            return <div className='issue-severity'>
              {getIssueSeverity(data.trusthive_priority)}
            </div>;
          }}
        />

        <Column
          title="Fix time"
          dataIndex="fixTime"
          key="fixTime"
          render={(x, data) => {
            return <strong>{data.time_to_fix} mins</strong>;
          }}
        />

        <Column
          title="Detected on"
          dataIndex="detectedDate"
          key="detectedDate"
          render={(projectname, data) => {
            return <>{moment(data.last_detected_at).format('MMM Do')}</>;
          }}
        />
      </Table>
      
      <IssueDetailsDrawer
        issueDetails = {issueDetailsView}
        onClose={() => setIssueDetailsDrawer(false)}
        open={issueDetailsDrawer}
      />
    </div>
  );
};

export default ProjectBuild;