/**
 * TODO:
 * - first node on canvas never gets deleted
 * 
 */


import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';import { Link } from "react-router-dom";
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import axios from "axios";
import {useParams} from 'react-router-dom';
import {
  Avatar as AntAvatar,
  Button,
  Checkbox,
  Col,
  Collapse,
  message,
  Menu,
  Radio,
  Row,
  Badge
} from 'antd';

import {
  ExclamationCircleFill,
  Robot
} from "react-bootstrap-icons";
import securityChecklist from "../../data/securityChecklist";
import complianceChecklist from "../../data/complianceChecklist";
import API from "../../constants/api";
import { userDataStore } from "../../store/user";
import useDocumentTitle from '../../utils/pageTitle';
import Spacer from '../common/spacer';
import CommentsDrawer from '../canvas/commentsDrawer';
import BrandBtn from "../common/BrandBtn";

const { Panel } = Collapse;

const ProjectSecurity = (propsData) => {
  const {
    projectDetails,
    updateProjectDetails,
    saveProjectDetailsObjects,
    planCommentsDrawer, 
    setPlanCommentsDrawer
  } = propsData;
  
  const [activeSecureView, setActiveSecureView] = useState('security');
  const [activeComplianceFilter, setActiveComplianceFilter] = useState('All');
  const isEU_Flag = projectDetails.meta_data && projectDetails.meta_data.is_EU ? true : false;

  const onChangeComplianceFilter = (e) => {
    setActiveComplianceFilter(e.target.value);
  };

  return (
    <>
    {projectDetails && projectDetails.project_id &&
      <Row style={{height: '100%'}}>
        <Col span={3}>
          <div style={{padding: '50px 20px 50px 0'}}>
            <ul className="btn-menu-list">
              <li>
                <Button
                  type={activeSecureView === 'security' ? "primary" : "link"}
                  color="primary"
                  variant={activeSecureView === 'security' ? "filled" : ""}
                  block
                  onClick={() => setActiveSecureView('security')}
                >
                  {/* <FileText style={{verticalAlign: 'middle'}} />  */}Security Checklist
                </Button>
              </li>
              <li>
                <Button
                  type={activeSecureView === 'compliance' ? "primary" : "link"}
                  color="primary"
                  variant={activeSecureView === 'compliance' ? "filled" : ""}
                  block
                  onClick={() => setActiveSecureView('compliance')}
                >
                  {/* <FileText style={{verticalAlign: 'middle'}} />  */}Compliance Checklist
                </Button>
              </li>
            </ul>
          </div>
        </Col>
        <Col span={13} offset={1}>
          <div className={`${(isEU_Flag ? 'compliance_Checklist_hasEU_Flag' : '')}`} style={{padding: '50px 0'}}>
            {activeSecureView === 'security' ? <>
            <h2 style={{ margin: 0, padding: '0 0 50px 0', fontSize: '27px', fontWeight: 400 }}>Security Checklist</h2>
              {securityChecklist.map((i, index) => (
                <div
                  key={index} 
                >
                  <Collapse
                    key={i.id}
                    ghost
                    className="checklist-panel"
                  >
                    <Panel
                      header={i.title}
                      key={i.id}
                    >
                      {i.items.map((t, tIndex) => (
                        <div key={tIndex}>
                          <Checkbox onChange={null}>
                            <strong>{t.task}: </strong>
                            <span>{t.description}</span>
                          </Checkbox>
                          <Spacer size={10} />
                        </div>
                      ))}
                    </Panel>
                  </Collapse>
                </div>
              ))}
            </> : 
            <>
              <Row>
                <Col span={18}>
                  <h2 style={{ margin: 0, padding: '0', fontSize: '27px', fontWeight: 400 }}>Compliance Checklist</h2>
                  <Spacer size={20} />
                  <Radio.Group defaultValue="All" onChange={onChangeComplianceFilter}>
                    <Radio.Button value="All" key="All">All compliances</Radio.Button>    
                    {complianceChecklist.map((c, c_index) => (
                      <Radio.Button value={c.category} key={c_index}>{c.category}</Radio.Button>    
                    ))}
                  </Radio.Group>
                </Col>
                <Col span={6} style={{textAlign: 'right'}}>
                  <BrandBtn brand="vanta" preTxt="Sync with" />
                </Col>
              </Row>
              
              <Spacer size={50} />
              {complianceChecklist.map((c, c_index) => {
                return activeComplianceFilter === c.category || activeComplianceFilter === 'All' ?
                  <div key={c_index}>
                    <h3>{c.category}</h3>
                    {c.requirements.map((r, r_index) => (
                      <Collapse
                        key={r_index}
                        ghost
                        className={`checklist-panel ${(r.isEU_Flag ? 'isEU_Flag' : '')}`}
                      >
                        <Panel
                          header={(r.isEU_Flag) ?  <><Badge.Ribbon text="NEW" color="volcano" placement="start" /> {r.type}</> : r.type}
                          key={r_index}
                        >
                          {r.tasks.map((t, t_index) => (
                            <div key={t_index}>
                              {t.regulation ? 
                                <>
                                  <h4>{t.regulation}</h4>
                                  {t.tasks.map((ta, ta_index) => (
                                    <div key={ta_index}>
                                      <Checkbox onChange={null} key={ta_index}>
                                        <strong>{ta.task}: </strong>
                                        <span>{ta.description}</span>
                                      </Checkbox>
                                      <Spacer size={10} />
                                    </div>
                                  ))}
                                </> : 
                                <div key={t_index}>
                                  <Checkbox onChange={null} key={t_index}>
                                    <strong>{t.task}: </strong>
                                    <span>{t.description}</span>
                                  </Checkbox>
                                  <Spacer size={10} />
                                </div>
                              }
                            </div>
                          ))}
                        </Panel>
                      </Collapse>
                    ))}
                  </div> : <></>
              })}
            </>
          }
          </div>
        </Col>
        <Col span={6} offset={1}>
          {isEU_Flag &&
            <div className="help-blurb warning" style={{margin: '50px 20px 0 0'}}>
              <h3>
                {<ExclamationCircleFill />}
                Compliance checks need your attention
              </h3>
              <Spacer size={40} />
              <div className="bot-message-blurb">
                  <AntAvatar src={<Robot />} className="bot-avatar"/>
                  <div className="message">
                      <h4>SA Agent</h4>
                      <p style={{background: 'transparent', padding: 0}}>
                          Project details have been updated <strong>Regions: EU</strong>.<br />
                          There are additional Compliance checks that need to be looked at.
                      </p>
                  </div>
              </div>
            </div>
          }
        </Col>
      </Row>
      }
    </>
  );
};

export default ProjectSecurity;