const issues = [
    {
        "id": 2788804,
        "package_name": "Root account should have MFA enabled",
        "initial_priority": 0,
        "timestamp": 1727362134,
        "closed_timestamp": -1,
        "status": "open",
        "type": "cloud",
        "task_id": null,
        "sla_remediate_by_date": -1,
        "sla_due_soon_date": -1,
        "tasks": [],
        "issues": [
            {
                "id": 13237574,
                "type": "cloud",
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362134,
                "cloud_id": 9050,
                "domain_id": -1,
                "attack_surface": "cloud",
                "trusthive_priority": 100,
                "initial_priority": 1,
                "trusthive_score_reasons": [],
                "ignored_reason": "",
                "first_detected_at": 1727362134,
                "last_detected_at": 1727362134,
                "region": "global",
                "resource_id": "demo_arn_for_group_7466",
                "resource_name": "ROOT",
                "resource_kind": "aws_user",
                "trusthive_rule_code": "aik_cloud_aws_001",
                "cloud_type": "aws",
                "cloud_name": "AWS production",
                "cloud_repo_name": null,
                "cloud_account_purpose": "production",
                "cloud_account_id": "demo_id_for_group_7466",
                "metadata": [],
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "title": "Root account should have MFA enabled",
                "description": "The root account is the most privileged user in an AWS account. MFA adds an extra layer of protection on top of a user name and password.",
                "remediation": "Using the IAM console navigate to Dashboard and expand Activate MFA on your root account.",
                "background_info_url": "https:\/\/docs.aws.amazon.com\/IAM\/latest\/UserGuide\/id_root-user.html#id_root-user_manage_mfa"
            }
        ],
        "trusthive_priority": 100,
        "time_to_fix": 5,
        "last_detected_at": 1727362134,
        "enhanced_status": "todo"
    },
    {
        "id": 2788803,
        "package_name": "Malicious package detected: axzios",
        "timestamp": 1727362134,
        "closed_timestamp": -1,
        "initial_priority": 100,
        "status": "open",
        "type": "malware",
        "task_id": null,
        "sla_remediate_by_date": -1,
        "sla_due_soon_date": -1,
        "tasks": [],
        "issues": [
            {
                "id": 13237573,
                "type": "malware",
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362134,
                "repo_name": "demo-app-1",
                "repo_id": 448990,
                "scm_repo_uid": "https:\/\/api.github.com\/repos\/trusthiveSec\/demo-app-1\/zipball\/",
                "scm_repo_default_branch": "master",
                "path_to_scan": null,
                "domain_id": -1,
                "attack_surface": "frontend",
                "trusthive_priority": 100,
                "initial_priority": 100,
                "trusthive_score_reasons": [],
                "ignored_reason": "",
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "package_name": "axzios",
                "package_version": "1.0.2",
                "first_detected_at": 1727362134,
                "last_detected_at": 1727362134,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "target_path": "package-lock.json"
            }
        ],
        "trusthive_priority": 100,
        "time_to_fix": 30,
        "last_detected_at": 1727362134,
        "programming_language": "JS",
        "indicators": null,
        "enhanced_status": "todo"
    },
    {
        "id": 2788849,
        "package_name": "tough-cookie",
        "timestamp": 1727362137,
        "closed_timestamp": -1,
        "initial_priority": 98,
        "status": "open",
        "type": "open_source",
        "sla_remediate_by_date": -1,
        "sla_due_soon_date": -1,
        "task_id": null,
        "tasks": [],
        "issues": [
            {
                "id": 13237620,
                "type": "open_source",
                "related_type_id": 9666516,
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362137,
                "repo_name": "demo-app-1",
                "scm_repo_uid": "https:\/\/api.github.com\/repos\/trusthiveSec\/demo-app-1\/zipball\/",
                "scm_repo_default_branch": "master",
                "path_to_scan": null,
                "repo_id": 448990,
                "domain_id": -1,
                "attack_surface": "frontend",
                "target_path": "package-lock.json",
                "cloud_repo_id": null,
                "cloud_repo_name": null,
                "cloud_id": -1,
                "cloud_type": null,
                "cloud_name": null,
                "cloud_account_id": null,
                "installed_version": "2.4.3",
                "patched_versions": [
                    "4.1.3"
                ],
                "cve_id": "CVE-2023-26136",
                "trusthive_priority": 99,
                "initial_priority": 98,
                "cwe_descriptions": [
                    "Abuse of JavaScript's prototype API possible (prototype pollution)"
                ],
                "cwe_descriptions_by_cwe": {
                    "CWE-1321": "Abuse of JavaScript's prototype API possible (prototype pollution)"
                },
                "cwe_classes": [
                    "CWE-1321"
                ],
                "trusthive_score_reasons": [
                    {
                        "rule": "fake_dev_dependency",
                        "applied": false,
                        "parent_packages": [
                            "request",
                            "node-gyp",
                            "node-sass",
                            "open-graph"
                        ],
                        "rule_info": "This package is part of the toolchain you are using to build & test your application. Since it's not being shipped to production, solving the issue is not urgent.",
                        "rule_title": "Dependency not used in production",
                        "severity_up_down": "down"
                    },
                    {
                        "rule": "poc_exists_for_cve",
                        "rule_info": "Proof of concept exploitation code for this CVE is available on Github. This inceases the risk of this vulnerablility being exploided.",
                        "rule_title": "Proof of concept on Github",
                        "severity_up_down": "up"
                    }
                ],
                "last_detected_at": 1727362137,
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "cloud_instance_group_name": null,
                "cloud_instance_group_id": -1,
                "description": "Versions of the package tough-cookie before 4.1.3 are vulnerable to Prototype Pollution due to improper handling of Cookies when using CookieJar in rejectPublicSuffixes=false mode. This issue arises from the manner in which the objects are initialized.The worst case impact for these vulnerabilities can be Abuse of JavaScript's prototype API possible (Prototype Pollution).",
                "remediation": "Allo Trusthive to create a PR with the fix or you can do it yourself.",
            }
        ],
        "trusthive_priority": 99,
        "time_to_fix": 60,
        "nearest_patch_version": null,
        "lowest_installed_version": null,
        "enhanced_status": "todo",
        "programming_language": "JS",
        "last_detected_at": 1727362137
    },
    {
        "id": 2788838,
        "package_name": "open-graph",
        "timestamp": 1727362136,
        "closed_timestamp": -1,
        "initial_priority": 98,
        "status": "open",
        "type": "open_source",
        "sla_remediate_by_date": -1,
        "sla_due_soon_date": -1,
        "task_id": null,
        "tasks": [],
        "issues": [
            {
                "id": 13237609,
                "type": "open_source",
                "related_type_id": 9666505,
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362136,
                "repo_name": "demo-app-1",
                "scm_repo_uid": "https:\/\/api.github.com\/repos\/trusthiveSec\/demo-app-1\/zipball\/",
                "scm_repo_default_branch": "master",
                "path_to_scan": null,
                "repo_id": 448990,
                "domain_id": -1,
                "attack_surface": "frontend",
                "target_path": "package-lock.json",
                "cloud_repo_id": null,
                "cloud_repo_name": null,
                "cloud_id": -1,
                "cloud_type": null,
                "cloud_name": null,
                "cloud_account_id": null,
                "installed_version": "0.2.4",
                "patched_versions": [
                    "0.2.6"
                ],
                "cve_id": "CVE-2021-23419",
                "trusthive_priority": 98,
                "initial_priority": 98,
                "cwe_descriptions": [
                    "Abuse of JavaScript's prototype API possible (prototype pollution)"
                ],
                "cwe_descriptions_by_cwe": {
                    "CWE-1321": "Abuse of JavaScript's prototype API possible (prototype pollution)"
                },
                "cwe_classes": [
                    "CWE-1321"
                ],
                "trusthive_score_reasons": [
                    {
                        "rule": "fake_dev_dependency",
                        "applied": false,
                        "parent_packages": [
                            "open-graph"
                        ],
                        "rule_info": "This package is part of the toolchain you are using to build & test your application. Since it's not being shipped to production, solving the issue is not urgent.",
                        "rule_title": "Dependency not used in production",
                        "severity_up_down": "down"
                    }
                ],
                "last_detected_at": 1727362136,
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "cloud_instance_group_name": null,
                "cloud_instance_group_id": -1
            }
        ],
        "trusthive_priority": 98,
        "time_to_fix": 15,
        "nearest_patch_version": null,
        "lowest_installed_version": null,
        "enhanced_status": "todo",
        "programming_language": "JS",
        "last_detected_at": 1727362136
    },
    {
        "id": 2788828,
        "package_name": "json-schema",
        "timestamp": 1727362136,
        "closed_timestamp": -1,
        "initial_priority": 98,
        "status": "open",
        "type": "open_source",
        "sla_remediate_by_date": -1,
        "sla_due_soon_date": -1,
        "task_id": null,
        "tasks": [],
        "issues": [
            {
                "id": 13237599,
                "type": "open_source",
                "related_type_id": 9666495,
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362136,
                "repo_name": "demo-app-1",
                "scm_repo_uid": "https:\/\/api.github.com\/repos\/trusthiveSec\/demo-app-1\/zipball\/",
                "scm_repo_default_branch": "master",
                "path_to_scan": null,
                "repo_id": 448990,
                "domain_id": -1,
                "attack_surface": "frontend",
                "target_path": "package-lock.json",
                "cloud_repo_id": null,
                "cloud_repo_name": null,
                "cloud_id": -1,
                "cloud_type": null,
                "cloud_name": null,
                "cloud_account_id": null,
                "installed_version": "0.2.3",
                "patched_versions": [
                    "0.4.0"
                ],
                "cve_id": "CVE-2021-3918",
                "trusthive_priority": 98,
                "initial_priority": 98,
                "cwe_descriptions": [
                    "Abuse of JavaScript's prototype API possible (prototype pollution)"
                ],
                "cwe_descriptions_by_cwe": {
                    "CWE-1321": "Abuse of JavaScript's prototype API possible (prototype pollution)"
                },
                "cwe_classes": [
                    "CWE-1321"
                ],
                "trusthive_score_reasons": [
                    {
                        "rule": "fake_dev_dependency",
                        "applied": false,
                        "parent_packages": [
                            "request",
                            "node-gyp",
                            "node-sass",
                            "open-graph"
                        ],
                        "rule_info": "This package is part of the toolchain you are using to build & test your application. Since it's not being shipped to production, solving the issue is not urgent.",
                        "rule_title": "Dependency not used in production",
                        "severity_up_down": "down"
                    }
                ],
                "last_detected_at": 1727362136,
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "cloud_instance_group_name": null,
                "cloud_instance_group_id": -1
            }
        ],
        "trusthive_priority": 98,
        "time_to_fix": 30,
        "nearest_patch_version": null,
        "lowest_installed_version": null,
        "enhanced_status": "todo",
        "programming_language": "JS",
        "last_detected_at": 1727362136
    },
    {
        "id": 2788818,
        "package_name": "chart.js",
        "timestamp": 1727362135,
        "closed_timestamp": -1,
        "initial_priority": 98,
        "status": "open",
        "type": "open_source",
        "sla_remediate_by_date": -1,
        "sla_due_soon_date": -1,
        "task_id": null,
        "tasks": [],
        "issues": [
            {
                "id": 13237588,
                "type": "open_source",
                "related_type_id": 9666484,
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362135,
                "repo_name": "demo-app-1",
                "scm_repo_uid": "https:\/\/api.github.com\/repos\/trusthiveSec\/demo-app-1\/zipball\/",
                "scm_repo_default_branch": "master",
                "path_to_scan": null,
                "repo_id": 448990,
                "domain_id": -1,
                "attack_surface": "frontend",
                "target_path": "package-lock.json",
                "cloud_repo_id": null,
                "cloud_repo_name": null,
                "cloud_id": -1,
                "cloud_type": null,
                "cloud_name": null,
                "cloud_account_id": null,
                "installed_version": "2.7.3",
                "patched_versions": [
                    "2.9.4"
                ],
                "cve_id": "CVE-2020-7746",
                "trusthive_priority": 98,
                "initial_priority": 98,
                "cwe_descriptions": [
                    "Abuse of JavaScript's prototype API possible (prototype pollution)"
                ],
                "cwe_descriptions_by_cwe": {
                    "CWE-1321": "Abuse of JavaScript's prototype API possible (prototype pollution)"
                },
                "cwe_classes": [
                    "CWE-1321"
                ],
                "trusthive_score_reasons": [
                    {
                        "rule": "fake_dev_dependency",
                        "applied": false,
                        "parent_packages": [
                            "chart.js"
                        ],
                        "rule_info": "This package is part of the toolchain you are using to build & test your application. Since it's not being shipped to production, solving the issue is not urgent.",
                        "rule_title": "Dependency not used in production",
                        "severity_up_down": "down"
                    }
                ],
                "last_detected_at": 1727362135,
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "cloud_instance_group_name": null,
                "cloud_instance_group_id": -1
            }
        ],
        "trusthive_priority": 98,
        "time_to_fix": 30,
        "nearest_patch_version": null,
        "lowest_installed_version": null,
        "enhanced_status": "todo",
        "programming_language": "JS",
        "last_detected_at": 1727362135
    },
    {
        "id": 2788812,
        "package_name": "curl",
        "timestamp": 1727362134,
        "closed_timestamp": -1,
        "initial_priority": 98,
        "status": "open",
        "type": "open_source",
        "sla_remediate_by_date": -1,
        "sla_due_soon_date": -1,
        "task_id": null,
        "tasks": [],
        "issues": [
            {
                "id": 13237582,
                "type": "open_source",
                "related_type_id": 9666481,
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362134,
                "repo_name": null,
                "scm_repo_uid": null,
                "scm_repo_default_branch": null,
                "path_to_scan": null,
                "repo_id": null,
                "domain_id": -1,
                "attack_surface": "docker_container",
                "target_path": "\/var\/lib\/rpm\/Packages",
                "cloud_repo_id": 73052,
                "cloud_repo_name": "demo-quay-container",
                "cloud_id": -1,
                "cloud_type": null,
                "cloud_name": null,
                "cloud_account_id": null,
                "installed_version": "7.29.0-59.el7",
                "patched_versions": [
                    ">7.73.0"
                ],
                "cve_id": "CVE-2020-8284",
                "trusthive_priority": 98,
                "initial_priority": 98,
                "cwe_descriptions": [
                    "Accidental exposure of sensitive info possible"
                ],
                "cwe_descriptions_by_cwe": {
                    "NVD-CWE-noinfo": null,
                    "CWE-200": "Accidental exposure of sensitive info possible"
                },
                "cwe_classes": [
                    "NVD-CWE-noinfo",
                    "CWE-200"
                ],
                "trusthive_score_reasons": [],
                "last_detected_at": 1727362134,
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "cloud_instance_group_name": null,
                "cloud_instance_group_id": -1
            }
        ],
        "trusthive_priority": 98,
        "time_to_fix": 30,
        "nearest_patch_version": null,
        "lowest_installed_version": null,
        "enhanced_status": "todo",
        "programming_language": "",
        "last_detected_at": 1727362134
    },
    {
        "id": 2788962,
        "package_name": "Open redirect can be used in social engineering attacks",
        "timestamp": 1727362169,
        "closed_timestamp": -1,
        "initial_priority": 91,
        "status": "open",
        "type": "sast",
        "task_id": null,
        "sla_remediate_by_date": -1,
        "sla_due_soon_date": -1,
        "tasks": [],
        "issues": [
            {
                "id": 13237750,
                "type": "sast",
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362169,
                "cloud_id": -1,
                "domain_id": -1,
                "attack_surface": "backend",
                "trusthive_priority": 91,
                "initial_priority": 91,
                "trusthive_score_reasons": [],
                "ignored_reason": "",
                "description": "An open redirect allows an attacker to use your app to perform social engineering attacks by redirecting users to other top-level domains (eg evilsite.com). It can usually also be used to combine with other exploits that could result in stolen credentials and user account takeover.",
                "first_detected_at": 1727362169,
                "last_detected_at": 1727362169,
                "introduced_at": 0,
                "rule_id": "express_open_redirect",
                "file": "src\/index.js",
                "file_breakable": "src\/index.js",
                "author": "",
                "start_line": 10,
                "end_line": 10,
                "start_column": 3,
                "end_column": 56,
                "scanner": "semgrep-custom",
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "scm_repo_uid": "https:\/\/api.github.com\/repos\/trusthiveSec\/demo-app-1\/zipball\/",
                "scm_repo_default_branch": "master",
                "path_to_scan": null,
                "snippet": "res.redirect(`http:\/\/${req.hostname}:3000${req.url}`);",
                "scm_repo_name": "demo-app-1",
                "scm_repo_id": 448990,
                "scm_repo_owner_name": "trusthiveSec",
                "repo_name": "demo-app-1",
                "repo_id": 448990,
                "repo_owner_name": "trusthiveSec",
                "title": "Open redirect can be used in social engineering attacks",
                "remediation": "You should only ignore this finding if you've ensured that the domain you are redirecting your user to is a domain from a whitelist.",
                "background_info_url": "",
                "cwe_classes": [
                    "CWE-601"
                ]
            }
        ],
        "trusthive_priority": 91,
        "time_to_fix": 30,
        "programming_language": "JS",
        "last_detected_at": 1727362169,
        "enhanced_status": "todo"
    },
    {
        "id": 2788820,
        "package_name": "crypto-js",
        "timestamp": 1727362135,
        "closed_timestamp": -1,
        "initial_priority": 91,
        "status": "open",
        "type": "open_source",
        "sla_remediate_by_date": -1,
        "sla_due_soon_date": -1,
        "task_id": null,
        "tasks": [],
        "issues": [
            {
                "id": 13237590,
                "type": "open_source",
                "related_type_id": 9666486,
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362135,
                "repo_name": "demo-app-1",
                "scm_repo_uid": "https:\/\/api.github.com\/repos\/trusthiveSec\/demo-app-1\/zipball\/",
                "scm_repo_default_branch": "master",
                "path_to_scan": null,
                "repo_id": 448990,
                "domain_id": -1,
                "attack_surface": "frontend",
                "target_path": "package-lock.json",
                "cloud_repo_id": null,
                "cloud_repo_name": null,
                "cloud_id": -1,
                "cloud_type": null,
                "cloud_name": null,
                "cloud_account_id": null,
                "installed_version": "3.3.0",
                "patched_versions": [
                    "4.2.0"
                ],
                "cve_id": "CVE-2023-46233",
                "trusthive_priority": 91,
                "initial_priority": 91,
                "cwe_descriptions": [
                    "Attacker can forge cryptographic signature"
                ],
                "cwe_descriptions_by_cwe": {
                    "CWE-327": "Attacker can forge cryptographic signature",
                    "CWE-328": null,
                    "CWE-916": null
                },
                "cwe_classes": [
                    "CWE-327",
                    "CWE-328",
                    "CWE-916"
                ],
                "trusthive_score_reasons": [
                    {
                        "rule": "fake_dev_dependency",
                        "applied": false,
                        "parent_packages": [
                            "auth0-js"
                        ],
                        "rule_info": "This package is part of the toolchain you are using to build & test your application. Since it's not being shipped to production, solving the issue is not urgent.",
                        "rule_title": "Dependency not used in production",
                        "severity_up_down": "down"
                    }
                ],
                "last_detected_at": 1727362135,
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "cloud_instance_group_name": null,
                "cloud_instance_group_id": -1
            }
        ],
        "trusthive_priority": 91,
        "time_to_fix": 60,
        "nearest_patch_version": null,
        "lowest_installed_version": null,
        "enhanced_status": "todo",
        "programming_language": "JS",
        "last_detected_at": 1727362135
    },
    {
        "id": 2788809,
        "package_name": "Content Security Policy (CSP) header not set",
        "timestamp": 1727362134,
        "closed_timestamp": -1,
        "initial_priority": 0,
        "status": "open",
        "type": "surface_monitoring",
        "task_id": null,
        "sla_remediate_by_date": -1,
        "sla_due_soon_date": -1,
        "tasks": [],
        "issues": [
            {
                "id": 13237579,
                "type": "surface_monitoring",
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362134,
                "cloud_id": -1,
                "cloud_name": null,
                "cloud_repo_id": null,
                "cloud_repo_name": null,
                "repo_name": "demo-app-1",
                "repo_id": 448990,
                "domain_id": 7832,
                "attack_surface": "cloud",
                "trusthive_priority": 91,
                "initial_priority": 1,
                "trusthive_score_reasons": [],
                "ignored_reason": "",
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "scanner": "owasp-zap",
                "target_url": "https:\/\/www.demo-app.example",
                "first_detected_at": 1727362134,
                "last_detected_at": 1727362134,
                "rule_id": "owasp-zap_10038-1",
                "closed_timestamp": -1,
                "ignored_at": null,
                "domain_name": "https:\/\/www.demo-app.example",
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "title": "Content Security Policy (CSP) header not set",
                "description": "Content Security Policy (CSP) is a first line of defense against common attacks including Cross Site Scripting (XSS) and data injection attacks. These attacks are used for everything from data theft via account takeovers to site defacement or distribution of malware. CSP config allows you to declare what content can be loaded and executed via a standard HTTP header. You can whitelist JavaScript, CSS, HTML frames, fonts, images and embeddable objects such as Java applets, ActiveX, audio and video files.",
                "remediation": "Ensure that your web server, application server, load balancer, etc. is configured to set the Content-Security-Policy header.",
                "background_info_url": "https:\/\/developer.mozilla.org\/en-US\/docs\/Web\/Security\/CSP\/Introducing_Content_Security_Policy",
                "metadata": {
                    "other": "",
                    "cwe_id": "319",
                    "evidence": "",
                    "confidence": "High",
                    "initial_risk": "Medium",
                    "input_vector": ""
                }
            }
        ],
        "trusthive_priority": 91,
        "time_to_fix": 90,
        "last_detected_at": 1727362134,
        "enhanced_status": "todo"
    },
    {
        "id": 2788807,
        "package_name": "TLS not enforced with valid HSTS header",
        "timestamp": 1727362134,
        "closed_timestamp": -1,
        "initial_priority": 0,
        "status": "open",
        "type": "surface_monitoring",
        "task_id": null,
        "sla_remediate_by_date": -1,
        "sla_due_soon_date": -1,
        "tasks": [],
        "issues": [
            {
                "id": 13237577,
                "type": "surface_monitoring",
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362134,
                "cloud_id": -1,
                "cloud_name": null,
                "cloud_repo_id": null,
                "cloud_repo_name": null,
                "repo_name": "demo-app-1",
                "repo_id": 448990,
                "domain_id": 7832,
                "attack_surface": "cloud",
                "trusthive_priority": 91,
                "initial_priority": 1,
                "trusthive_score_reasons": [],
                "ignored_reason": "",
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "scanner": "owasp-zap",
                "target_url": "https:\/\/www.demo-app.example",
                "first_detected_at": 1727362134,
                "last_detected_at": 1727362134,
                "rule_id": "owasp-zap_10035",
                "closed_timestamp": -1,
                "ignored_at": null,
                "domain_name": "https:\/\/www.demo-app.example",
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "title": "TLS not enforced with valid HSTS header",
                "description": "HTTP Strict Transport Security (HSTS) is a mechanism whereby a web server forces web browsers to interact with it using only secure HTTPS connections (i.e. HTTP layered over TLS\/SSL). By enforcing this, you are protecting your users from various man-in-the-middle attacks because it's harder for attackers to downgrade connections to HTTP instead of HTTPS.",
                "remediation": "Ensure that your web server, application server, load balancer, etc. is configured to send a valid Strict-Transport-Security header.",
                "background_info_url": "https:\/\/developer.mozilla.org\/en-US\/docs\/Web\/HTTP\/Headers\/Strict-Transport-Security",
                "metadata": {
                    "other": "",
                    "cwe_id": "319",
                    "evidence": "",
                    "confidence": "High",
                    "initial_risk": "Low",
                    "input_vector": ""
                }
            }
        ],
        "trusthive_priority": 91,
        "time_to_fix": 5,
        "last_detected_at": 1727362134,
        "enhanced_status": "todo"
    },
    {
        "id": 2788963,
        "package_name": "Using var_dump() can expose sensitive info to users",
        "timestamp": 1727362169,
        "closed_timestamp": -1,
        "initial_priority": 90,
        "status": "open",
        "type": "sast",
        "task_id": null,
        "sla_remediate_by_date": -1,
        "sla_due_soon_date": -1,
        "tasks": [],
        "issues": [
            {
                "id": 13237751,
                "type": "sast",
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362169,
                "cloud_id": -1,
                "domain_id": -1,
                "attack_surface": "backend",
                "trusthive_priority": 90,
                "initial_priority": 90,
                "trusthive_score_reasons": [],
                "ignored_reason": "",
                "description": "Using var_dump() can expose private data to end users. This is likely a debug statement that was accidentally committed.",
                "first_detected_at": 1727362169,
                "last_detected_at": 1727362169,
                "introduced_at": 0,
                "rule_id": "AIK_vardump-use",
                "file": "src\/index.php",
                "file_breakable": "src\/index.php",
                "author": "",
                "start_line": 4,
                "end_line": 4,
                "start_column": 1,
                "end_column": 14,
                "scanner": "semgrep-custom",
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "scm_repo_uid": "https:\/\/api.github.com\/repos\/trusthiveSec\/demo-app-1\/zipball\/",
                "scm_repo_default_branch": "master",
                "path_to_scan": null,
                "snippet": "var_dump($a);",
                "scm_repo_name": "demo-app-1",
                "scm_repo_id": 448990,
                "scm_repo_owner_name": "trusthiveSec",
                "repo_name": "demo-app-1",
                "repo_id": 448990,
                "repo_owner_name": "trusthiveSec",
                "title": "Using var_dump() can expose sensitive info to users",
                "remediation": "Remove any usage var_dump from your codebase",
                "background_info_url": "",
                "cwe_classes": [
                    "CWE-200"
                ]
            }
        ],
        "trusthive_priority": 90,
        "time_to_fix": 5,
        "programming_language": "PHP",
        "last_detected_at": 1727362169,
        "enhanced_status": "todo"
    },
    {
        "id": 2788927,
        "package_name": "Libexpat",
        "timestamp": 1727362166,
        "closed_timestamp": -1,
        "initial_priority": 90,
        "status": "open",
        "type": "open_source",
        "sla_remediate_by_date": -1,
        "sla_due_soon_date": -1,
        "task_id": null,
        "tasks": [],
        "issues": [
            {
                "id": 13237712,
                "type": "open_source",
                "related_type_id": 9666590,
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362166,
                "repo_name": null,
                "scm_repo_uid": null,
                "scm_repo_default_branch": null,
                "path_to_scan": null,
                "repo_id": null,
                "domain_id": -1,
                "attack_surface": "docker_container",
                "target_path": "\/var\/lib\/dpkg\/status",
                "cloud_repo_id": 73051,
                "cloud_repo_name": "nginx",
                "cloud_id": -1,
                "cloud_type": null,
                "cloud_name": null,
                "cloud_account_id": null,
                "installed_version": "2.5.0-1",
                "patched_versions": [
                    "2.5.0-1+deb12u1"
                ],
                "cve_id": "CVE-2024-45490",
                "trusthive_priority": 90,
                "initial_priority": 90,
                "cwe_descriptions": [
                    "XXE attack may allow sensitive info exposure"
                ],
                "cwe_descriptions_by_cwe": {
                    "CWE-611": "XXE attack may allow sensitive info exposure"
                },
                "cwe_classes": [
                    "CWE-611"
                ],
                "trusthive_score_reasons": [],
                "last_detected_at": 1727362166,
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "cloud_instance_group_name": null,
                "cloud_instance_group_id": -1
            },
            {
                "id": 13237711,
                "type": "open_source",
                "related_type_id": 9666589,
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362166,
                "repo_name": null,
                "scm_repo_uid": null,
                "scm_repo_default_branch": null,
                "path_to_scan": null,
                "repo_id": null,
                "domain_id": -1,
                "attack_surface": "docker_container",
                "target_path": "\/var\/lib\/dpkg\/status",
                "cloud_repo_id": 73051,
                "cloud_repo_name": "nginx",
                "cloud_id": -1,
                "cloud_type": null,
                "cloud_name": null,
                "cloud_account_id": null,
                "installed_version": "2.5.0-1",
                "patched_versions": [
                    "2.5.0-1+deb12u1"
                ],
                "cve_id": "CVE-2024-45491",
                "trusthive_priority": 90,
                "initial_priority": 90,
                "cwe_descriptions": [
                    "Integer overflow can lead to further weaknesses",
                    "Integer overflow can lead to further weaknesses"
                ],
                "cwe_descriptions_by_cwe": {
                    "CWE-190": "Integer overflow can lead to further weaknesses"
                },
                "cwe_classes": [
                    "CWE-190",
                    "CWE-190"
                ],
                "trusthive_score_reasons": [],
                "last_detected_at": 1727362166,
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "cloud_instance_group_name": null,
                "cloud_instance_group_id": -1
            },
            {
                "id": 13237710,
                "type": "open_source",
                "related_type_id": 9666588,
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362166,
                "repo_name": null,
                "scm_repo_uid": null,
                "scm_repo_default_branch": null,
                "path_to_scan": null,
                "repo_id": null,
                "domain_id": -1,
                "attack_surface": "docker_container",
                "target_path": "\/var\/lib\/dpkg\/status",
                "cloud_repo_id": 73051,
                "cloud_repo_name": "nginx",
                "cloud_id": -1,
                "cloud_type": null,
                "cloud_name": null,
                "cloud_account_id": null,
                "installed_version": "2.5.0-1",
                "patched_versions": [
                    "2.5.0-1+deb12u1"
                ],
                "cve_id": "CVE-2024-45492",
                "trusthive_priority": 90,
                "initial_priority": 90,
                "cwe_descriptions": [
                    "Integer overflow can lead to further weaknesses",
                    "Integer overflow can lead to further weaknesses"
                ],
                "cwe_descriptions_by_cwe": {
                    "CWE-190": "Integer overflow can lead to further weaknesses"
                },
                "cwe_classes": [
                    "CWE-190",
                    "CWE-190"
                ],
                "trusthive_score_reasons": [],
                "last_detected_at": 1727362166,
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "cloud_instance_group_name": null,
                "cloud_instance_group_id": -1
            }
        ],
        "trusthive_priority": 90,
        "time_to_fix": 15,
        "nearest_patch_version": null,
        "lowest_installed_version": null,
        "enhanced_status": "todo",
        "programming_language": "",
        "last_detected_at": 1727362166
    },
    {
        "id": 2788895,
        "package_name": "Load balancer is using outdated TLS policy",
        "timestamp": 1727362147,
        "closed_timestamp": -1,
        "initial_priority": 90,
        "status": "open",
        "type": "sast",
        "task_id": null,
        "sla_remediate_by_date": -1,
        "sla_due_soon_date": -1,
        "tasks": [],
        "issues": [
            {
                "id": 13237668,
                "type": "sast",
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362147,
                "cloud_id": -1,
                "domain_id": -1,
                "attack_surface": "backend",
                "trusthive_priority": 90,
                "initial_priority": 90,
                "trusthive_score_reasons": [],
                "ignored_reason": "",
                "description": "An Application load balancer is using an oudated TLS policy. Some older versions of SSL are broken in various ways, such as TLS1.1 and SSLv3.",
                "first_detected_at": 1727362147,
                "last_detected_at": 1727362147,
                "introduced_at": 0,
                "rule_id": "CKV_AWS_103",
                "file": "config\/load_balancer.tf",
                "file_breakable": "config\/load_balancer.tf",
                "author": "",
                "start_line": 25,
                "end_line": 34,
                "start_column": 0,
                "end_column": 0,
                "scanner": "checkov",
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "scm_repo_uid": "https:\/\/api.github.com\/repos\/trusthiveSec\/demo-app-1\/zipball\/",
                "scm_repo_default_branch": "master",
                "path_to_scan": null,
                "snippet": "resource \"aws_alb_listener\" \"frontend\" {\n  load_balancer_arn = aws_alb.main.arn\n  port              = 3000\n  protocol          = \"HTTP\"\n...\n    target_group_arn = \"${aws_alb_target_group.api.arn}\"\n    type             = \"forward\"\n  }\n}",
                "scm_repo_name": "demo-app-1",
                "scm_repo_id": 448990,
                "scm_repo_owner_name": "trusthiveSec",
                "repo_name": "demo-app-1",
                "repo_id": 448990,
                "repo_owner_name": "trusthiveSec",
                "title": "Load balancer is using outdated TLS policy",
                "remediation": "Upgrade the Application load balancer's TLS policy such as 'ELBSecurityPolicy-FS-1-2-Res-2020-10'",
                "background_info_url": "",
                "cwe_classes": []
            }
        ],
        "trusthive_priority": 90,
        "time_to_fix": 30,
        "programming_language": "",
        "last_detected_at": 1727362147,
        "enhanced_status": "todo"
    },
    {
        "id": 2788882,
        "package_name": "Android app enables debug mode in production",
        "timestamp": 1727362139,
        "closed_timestamp": -1,
        "initial_priority": 90,
        "status": "open",
        "type": "sast",
        "task_id": null,
        "sla_remediate_by_date": -1,
        "sla_due_soon_date": -1,
        "tasks": [],
        "issues": [
            {
                "id": 13237654,
                "type": "sast",
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362139,
                "cloud_id": -1,
                "domain_id": -1,
                "attack_surface": "backend",
                "trusthive_priority": 90,
                "initial_priority": 90,
                "trusthive_score_reasons": [],
                "ignored_reason": "",
                "description": "The AndroidManifest.xml file is configured with 'android:debuggable' set to true. This introduces a security risk, as debuggable applications may expose sensitive information and weaken security measures.",
                "first_detected_at": 1727362139,
                "last_detected_at": 1727362139,
                "introduced_at": 0,
                "rule_id": "AIK_android_debuggable",
                "file": "android\/AndroidManifest.xml",
                "file_breakable": "android\/AndroidManifest.xml",
                "author": "",
                "start_line": 1,
                "end_line": 9,
                "start_column": 1,
                "end_column": 12,
                "scanner": "semgrep-custom-v1",
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "scm_repo_uid": "https:\/\/api.github.com\/repos\/trusthiveSec\/demo-app-1\/zipball\/",
                "scm_repo_default_branch": "master",
                "path_to_scan": null,
                "snippet": "<manifest>\n    <application android:debuggable=\"true\">\n        <activity>\n            <intent-filter android:exported=\"true\">\n                <action android:name=\"android.intent.action.VIEW\" \/>\n            <\/intent-filter>\n        <\/activity>\n    <\/application>\n<\/manifest>",
                "scm_repo_name": "demo-app-1",
                "scm_repo_id": 448990,
                "scm_repo_owner_name": "trusthiveSec",
                "repo_name": "demo-app-1",
                "repo_id": 448990,
                "repo_owner_name": "trusthiveSec",
                "title": "Android app enables debug mode in production",
                "remediation": "Set 'android:debuggable' to false in the AndroidManifest.xml file.",
                "background_info_url": "https:\/\/developer.android.com\/guide\/topics\/manifest\/application-element#debug",
                "cwe_classes": [
                    "CWE-489"
                ]
            }
        ],
        "trusthive_priority": 90,
        "time_to_fix": 30,
        "programming_language": "Android",
        "last_detected_at": 1727362139,
        "enhanced_status": "todo"
    },
    {
        "id": 2788904,
        "package_name": "Secr*****ging",
        "timestamp": 1727362164,
        "closed_timestamp": -1,
        "initial_priority": 80,
        "status": "open",
        "type": "leaked_secret",
        "task_id": null,
        "sla_remediate_by_date": -1,
        "sla_due_soon_date": -1,
        "tasks": [],
        "issues": [
            {
                "id": 13237685,
                "type": "leaked_secret",
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362164,
                "cloud_id": -1,
                "domain_id": -1,
                "attack_surface": "backend",
                "trusthive_priority": 89,
                "initial_priority": 80,
                "trusthive_score_reasons": [
                    {
                        "rule": "file_likely_used_for_production",
                        "rule_title": "Secret located in production file",
                        "rule_info": "This secret was discovered inside a production related file, so trusthive assumes it's more likely to be a real secret. The priority of the issue was upgraded.",
                        "severity_up_down": "up"
                    }
                ],
                "ignored_reason": "",
                "description": "Iden*****ield",
                "first_detected_at": 1727362164,
                "introduced_at": 1690284047,
                "rule_id": "hashicorp-tf-password",
                "commit": "f5d0*****d0e7",
                "file": "env\/*****tion",
                "file_breakable": "env\/*****tion",
                "matched_line": "PASSWORD=***************123\"",
                "redacted_secret": "***************123\"",
                "author": "Kemosabert",
                "entropy": 3.68188,
                "start_line": 5,
                "end_line": 5,
                "start_column": 9,
                "end_column": 36,
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "scm_repo_name": "demo-app-1",
                "scm_repo_id": 448990,
                "scm_repo_owner_name": "trusthiveSec",
                "repo_name": "demo-app-1",
                "repo_id": 448990,
                "repo_owner_name": "trusthiveSec"
            },
            {
                "id": 13237684,
                "type": "leaked_secret",
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362164,
                "cloud_id": -1,
                "domain_id": -1,
                "attack_surface": "backend",
                "trusthive_priority": 9,
                "initial_priority": 80,
                "trusthive_score_reasons": [
                    {
                        "rule": "secret_test_file_unlikely_real",
                        "rule_title": "Secret located in a test file",
                        "rule_info": "This secret was discovered inside a test file, so trusthive assumes it's less likely to be a real secret. The priority of the issue was downgraded.",
                        "severity_up_down": "down"
                    },
                    {
                        "rule": "secret_doc_file_unlikely_real",
                        "rule_title": "Secret located in doc file",
                        "rule_info": "This secret was discovered inside documentation files, so trusthive assumes it's less likely to be a real secret. The priority of the issue was downgraded.",
                        "severity_up_down": "down"
                    }
                ],
                "ignored_reason": "",
                "description": "Iden*****ield",
                "first_detected_at": 1727362164,
                "introduced_at": 1690284047,
                "rule_id": "hashicorp-tf-password",
                "commit": "f5d0*****d0e7",
                "file": "env\/*****ging",
                "file_breakable": "env\/*****ging",
                "matched_line": "PASSWORD=***************123\"",
                "redacted_secret": "***************123\"",
                "author": "Kemosabert",
                "entropy": 3.68188,
                "start_line": 5,
                "end_line": 5,
                "start_column": 9,
                "end_column": 36,
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "scm_repo_name": "demo-app-1",
                "scm_repo_id": 448990,
                "scm_repo_owner_name": "trusthiveSec",
                "repo_name": "demo-app-1",
                "repo_id": 448990,
                "repo_owner_name": "trusthiveSec"
            }
        ],
        "trusthive_priority": 89,
        "time_to_fix": 60,
        "enhanced_status": "todo",
        "is_blurred": true
    },
    {
        "id": 2788960,
        "package_name": "Expr*****ders",
        "timestamp": 1727362169,
        "closed_timestamp": -1,
        "initial_priority": 85,
        "status": "open",
        "type": "sast",
        "task_id": null,
        "sla_remediate_by_date": -1,
        "sla_due_soon_date": -1,
        "tasks": [],
        "issues": [
            {
                "id": 13237748,
                "type": "sast",
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362169,
                "cloud_id": -1,
                "domain_id": -1,
                "attack_surface": "backend",
                "trusthive_priority": 85,
                "initial_priority": 85,
                "trusthive_score_reasons": [],
                "ignored_reason": "",
                "description": "You'*****rs. ",
                "first_detected_at": 1727362169,
                "last_detected_at": 1727362169,
                "introduced_at": 0,
                "rule_id": "AIK_express-without-helmet",
                "file": "src\/****x.js",
                "file_breakable": "src\/****x.js",
                "author": "",
                "start_line": 3,
                "end_line": 3,
                "start_column": 13,
                "end_column": 22,
                "scanner": "semgrep-custom",
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "scm_repo_uid": "https:\/\/api.github.com\/repos\/trusthiveSec\/demo-app-1\/zipball\/",
                "scm_repo_default_branch": "master",
                "path_to_scan": null,
                "snippet": "const app = express();",
                "scm_repo_name": "demo-app-1",
                "scm_repo_id": 448990,
                "scm_repo_owner_name": "trusthiveSec",
                "repo_name": "demo-app-1",
                "repo_id": 448990,
                "repo_owner_name": "trusthiveSec",
                "title": "Express is not emitting security headers",
                "remediation": "Use the Helmet express middleware with: \nconst helmet = require(\"helmet\"); \n app.use(helmet());",
                "background_info_url": "",
                "cwe_classes": [
                    "CWE-693"
                ]
            }
        ],
        "trusthive_priority": 85,
        "time_to_fix": 45,
        "programming_language": "JS",
        "last_detected_at": 1727362169,
        "enhanced_status": "todo",
        "is_blurred": true
    },
    {
        "id": 2788811,
        "package_name": "EC2 *****acks",
        "initial_priority": 0,
        "timestamp": 1727362134,
        "closed_timestamp": -1,
        "status": "open",
        "type": "cloud",
        "task_id": null,
        "sla_remediate_by_date": -1,
        "sla_due_soon_date": -1,
        "tasks": [],
        "issues": [
            {
                "id": 13237581,
                "type": "cloud",
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362134,
                "cloud_id": 9050,
                "domain_id": -1,
                "attack_surface": "cloud",
                "trusthive_priority": 85,
                "initial_priority": 1,
                "trusthive_score_reasons": [],
                "ignored_reason": "",
                "first_detected_at": 1727362134,
                "last_detected_at": 1727362134,
                "region": "us-east-1",
                "resource_id": "demo_arn_for_group_7466",
                "resource_name": "AWS EC2 instance",
                "resource_kind": "aws_ec2_instance",
                "trusthive_rule_code": "aik_cloud_aws_004",
                "cloud_type": "aws",
                "cloud_name": "AWS production",
                "cloud_repo_name": null,
                "cloud_account_purpose": "production",
                "cloud_account_id": "demo_id_for_group_7466",
                "metadata": [],
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "title": "EC2 IAM roles vulnerable to SSRF attacks",
                "description": "IMDS*****not.",
                "remediation": "Upgrading to Amazon Linux 2023 will automatically remediate this issue as it uses IMDSv2 by default. If you cannot upgrade, use the following code to enable stronger authentication:\naws ec2 modify-instance-metadata-options --instance-id i-1234567898ab --http-tokens required --http-endpoint enabled\n",
                "background_info_url": "https:\/\/docs.aws.amazon.com\/AWSEC2\/latest\/UserGuide\/configuring-instance-metadata-options.html"
            }
        ],
        "trusthive_priority": 85,
        "time_to_fix": 30,
        "last_detected_at": 1727362134,
        "enhanced_status": "todo",
        "is_blurred": true
    },
    {
        "id": 2788903,
        "package_name": "Secr*****i.js",
        "timestamp": 1727362164,
        "closed_timestamp": -1,
        "initial_priority": 80,
        "status": "open",
        "type": "leaked_secret",
        "task_id": null,
        "sla_remediate_by_date": -1,
        "sla_due_soon_date": -1,
        "tasks": [],
        "issues": [
            {
                "id": 13237683,
                "type": "leaked_secret",
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362164,
                "cloud_id": -1,
                "domain_id": -1,
                "attack_surface": "backend",
                "trusthive_priority": 80,
                "initial_priority": 80,
                "trusthive_score_reasons": [],
                "ignored_reason": "",
                "description": "Unco*****ata.",
                "first_detected_at": 1727362164,
                "introduced_at": 1701793419,
                "rule_id": "jwt",
                "commit": "0881*****3a12",
                "file": "pack*****x.js",
                "file_breakable": "pack*****x.js",
                "matched_line": "*******************************************************************************************************************************************************sw5c\"",
                "redacted_secret": "*******************************************************************************************************************************************************sw5c",
                "author": "Kemosabert",
                "entropy": 5.44407,
                "start_line": 6,
                "end_line": 6,
                "start_column": 31,
                "end_column": 186,
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "scm_repo_name": "demo-app-1",
                "scm_repo_id": 448990,
                "scm_repo_owner_name": "trusthiveSec",
                "repo_name": "demo-app-1",
                "repo_id": 448990,
                "repo_owner_name": "trusthiveSec"
            }
        ],
        "trusthive_priority": 80,
        "time_to_fix": 60,
        "enhanced_status": "todo",
        "is_blurred": true
    },
    {
        "id": 2788894,
        "package_name": "Load*****ders",
        "timestamp": 1727362147,
        "closed_timestamp": -1,
        "initial_priority": 80,
        "status": "open",
        "type": "sast",
        "task_id": null,
        "sla_remediate_by_date": -1,
        "sla_due_soon_date": -1,
        "tasks": [],
        "issues": [
            {
                "id": 13237667,
                "type": "sast",
                "status": "open",
                "enhanced_status": "todo",
                "timestamp": 1727362147,
                "cloud_id": -1,
                "domain_id": -1,
                "attack_surface": "backend",
                "trusthive_priority": 80,
                "initial_priority": 80,
                "trusthive_score_reasons": [],
                "ignored_reason": "",
                "description": "AWS'*****ers.",
                "first_detected_at": 1727362147,
                "last_detected_at": 1727362147,
                "introduced_at": 0,
                "rule_id": "CKV_AWS_131",
                "file": "conf*****r.tf",
                "file_breakable": "conf*****r.tf",
                "author": "",
                "start_line": 1,
                "end_line": 5,
                "start_column": 0,
                "end_column": 0,
                "scanner": "checkov",
                "ignore_reasons": [],
                "snooze_reason": {
                    "reason": "",
                    "by_user_name": ""
                },
                "snooze_until_timestamp": -1,
                "closed_timestamp": -1,
                "ignored_at": null,
                "user_severity_adjusted": 0,
                "user_severity_adjustment": null,
                "sla_remediate_by_date": -1,
                "scm_repo_uid": "https:\/\/api.github.com\/repos\/trusthiveSec\/demo-app-1\/zipball\/",
                "scm_repo_default_branch": "master",
                "path_to_scan": null,
                "snippet": "resource \"aws_alb\" \"main\" {\n  name            = \"demo-load-balancer\"\n  subnets         = [for subnet in aws_subnet.public: \"${subnet.id}\"]\n  security_groups = [aws_security_group.external.id]\n}",
                "scm_repo_name": "demo-app-1",
                "scm_repo_id": 448990,
                "scm_repo_owner_name": "trusthiveSec",
                "repo_name": "demo-app-1",
                "repo_id": 448990,
                "repo_owner_name": "trusthiveSec",
                "title": "Load balancer allows invalid HTTP headers",
                "remediation": "Enable the 'drop invalid headers' option on the Application Load Balancer",
                "background_info_url": "",
                "cwe_classes": []
            }
        ],
        "trusthive_priority": 80,
        "time_to_fix": 20,
        "programming_language": "",
        "last_detected_at": 1727362147,
        "enhanced_status": "todo",
        "is_blurred": true
    }
];

export default issues;