import {
  Bucket,
  BarChartSteps,
  ClipboardData,
  Database,
  Ethernet,
  Floppy,
  Globe2,
  HddNetwork,
  Person,
  PersonWorkspace,
  Shuffle,
  Terminal,
  TerminalSplit,
  Window,
  Images,
  CameraVideo,
  ArrowsCollapseVertical,
  RSquare,
  Infinity,
  Kanban,
  WindowStack,
  MenuButtonWideFill,
  Circle,
  Square,
  Triangle,
  Diamond,
  Puzzle
} from 'react-bootstrap-icons';
import TextIcon from '../modules/canvas/textIcon';

const sidebarComponents = [
  // {
  //   group: 'SHAPES',
  //   groupTitle: 'Shapes',
  //   components: [
  //     {
  //       key: 'CIRCLE',
  //       type:'node',
  //       icon: <Circle />,
  //       title: 'Circle',
  //       description: 'Some helpful text',
  //       metadata: []
  //     },
  //     {
  //       key: 'SQUARE',
  //       type:'node',
  //       icon: <Square />,
  //       title: 'Square',
  //       description: 'Some helpful text',
  //       metadata: []
  //     },
  //     {
  //       key: 'TRIANGLE',
  //       type:'node',
  //       icon: <Triangle />,
  //       title: 'Triangle',
  //       description: 'Some helpful text',
  //       metadata: []
  //     },
  //     {
  //       key: 'DIAMOND',
  //       type:'node',
  //       icon: <Diamond />,
  //       title: 'Diamond',
  //       description: 'Some helpful text',
  //       metadata: []
  //     },
  //   ]
  // },
  {
  group: 'GENERAL_COMPONENTS',
  groupTitle: 'General components',
  components: [
    {
      key: 'PROJECT',
      type:'node',
      sub_type: 'project',
      icon: <Puzzle />,
      title: 'Service/Project',
      description: 'Some helpful text',
      metadata: ["Authenticated"]
    },
    {
      key: 'USER',
      type:'node',
      icon: <Person />,
      title: 'User',
      description: 'Some helpful text',
      metadata: ["Authenticated"]
    },
    {
      key: 'ADMIN',
      type:'node',
      icon: <PersonWorkspace />,
      title: 'Admin',
      description: 'Some helpful text',
      metadata: ["Authenticated"]
    },
    {
      key: 'BROWSER',
      type:'node',
      icon: <Window />,
      title: 'Browser',
      description: 'Some helpful text',
       metadata:[""]
    },
    {
      key: 'API',
      type:'node',
      icon: <Terminal />,
      title: 'API',
      description: 'Some helpful text',
      metadata: ["Authenticated"]
    },
    {
      key: 'DATABASE',
      type:'node',
      icon: <Database />,
      title: 'Database',
      description: 'Some helpful text',
      metadata: ["Encrypted", "Logging", "Back up", "Write Access","Sensitive Data"]
    },
    {
      key: 'FILES',
      type:'node',
      icon: <Floppy />,
      title: 'Files',
      description: 'Some helpful text',
      metadata: ["Encrypted", "Logging", "Back up", "Write Access","Sensitive Data"]
    },
    {
      key: 'STORAGE_BUCKETS',
      type:'node',
      icon: <Bucket />,
      title: 'Storage Buckets',
      description: 'Some helpful text',
      metadata: ["Encrypted", "Logging", "Back up", "Write Access","Sensitive Data"]
    },
    {
      key: 'PROCESS',
      type:'node',
      icon: <Shuffle />,
      title: 'Process',
      description: '',
      metadata:["Authentication","Authorization","Input Validation"]
    },
    {
      key: 'HTTP',
      type:'node',
      icon: <TextIcon txt="HTTP"/>,
      title: 'HTTP',
      description: 'Some helpful text',
      metadata:["Authenticated","Encrypted"]
    },
    {
      key: 'HTTPS',
      type:'node',
      icon: <TextIcon txt="HTTPS"/>,
      title: 'HTTPs',
      description: 'Some helpful text',
      metadata:["Authenticated","Encrypted"]
    },
    {
      key: 'FTP',
      type:'node',
      icon: <TextIcon txt="FTP"/>,
      title: 'FTP',
      description: 'Some helpful text',
      metadata:["Authenticated","Encrypted"]
    }
  ]
},
{
  group: 'TRUST_BOUNDARY',
  groupTitle: 'Trust Boundry',
  components: [
    {
      key: 'INTERNET',
      type:'group',
      icon: <Globe2 />,
      title: 'Internet',
      description: 'Some helpful text',
       metadata:[]
    },
    {
      key: 'INTRANET',
      type:'group',
      icon: <Ethernet />,
      border: '',
      title: 'Intranet',
      description: 'Some helpful text',
       metadata:[]
    },
    {
      key: 'PRIVATE_NETWORK',
      type:'group',
      icon: <HddNetwork />,
      title: 'Private Network',
      description: 'Some helpful text',
       metadata:[]
    },
    {
      key: 'SOFTWARE_ISOLATION',
      type:'group',
      icon: <TerminalSplit />,
      title: 'Software Isolation',
      description: 'Some helpful text',
       metadata:[]
    },
  ]
},
{
  group: 'AI_ML',
  groupTitle: 'AI & ML',
  components: [
    {
      key: 'STRUCTURED_DATA',
      type:'node',
      icon: <ClipboardData />,
      title: 'Structured Data',
      description: 'Some helpful text',
      metadata:["Authenticated","Encrypted"]
    },
    {
      key: 'UNSTRUCTURED_DATA',
      title: 'Unstructured Data',
      icon: <BarChartSteps />,
      description: 'Some helpful text',
      type:'node',
      metadata:["Authenticated","Encrypted"]
    },
    {
      key: 'IMAGES',
      title: 'Images',
      icon: <Images />,
      description: 'Some helpful text',
      type:'node',
      metadata:["Authenticated","Encrypted"]
    },
    {
      key: 'VIDEOS',
      title: 'Videos',
      icon: <CameraVideo />,
      description: 'Some helpful text',
      type:'node',
      metadata:["Authenticated","Encrypted"]
    },
    {
      key: 'ETL',
      title: 'ETL',
      icon: <TextIcon txt="ETL"/>,
      description: 'Some helpful text',
      type:'node',
      metadata:["Authenticated","Encrypted"]
    },
    {
      key: 'INFERENCE',
      title: 'Inference',
      icon: <ArrowsCollapseVertical />,
      description: 'Some helpful text',
      type:'node',
      metadata:["Authenticated","Encrypted"]
    },
    {
      key: 'MODEL_TRAINING',
      title: 'Model Training',
      icon: <Infinity />,
      description: 'Some helpful text',
      type:'node',
      metadata:["Authenticated","Encrypted"]
    },
    {
      key: 'MODEL_DEPLOYMENT',
      title: 'Model Deployment',
      icon: <Terminal />,
      description: 'Some helpful text',
      type:'node',
      metadata:["Authenticated","Encrypted"]
    },
    {
      key: 'MODEL_REGISTRY',
      title: 'Model Registry',
      icon: <RSquare />,
      description: 'Some helpful text',
      type:'node',
      metadata:["Authenticated","Encrypted"]
    },
    {
      key: 'AI_MODEL',
      title: 'AI Model',
      icon: <MenuButtonWideFill />,
      description: 'Some helpful text',
      type:'node',
      metadata:["Authenticated","Encrypted"]
    },
    {
      key: 'REPORT',
      title: 'Report',
      icon: <Kanban />,
      description: 'Some helpful text',
      type:'node',
      metadata:["Authenticated","Encrypted"]
    },
    {
      key: 'BUSINESS_APP',
      title: 'Business App',
      icon: <WindowStack />,
      description: 'Some helpful text',
      type:'node',
      metadata:["Authenticated","Encrypted"]
    },
  ]
},
]

export default sidebarComponents;
