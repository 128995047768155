/**
 * TODO:
 * - first node on canvas never gets deleted
 * 
 */


import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';import { Link } from "react-router-dom";
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import axios from "axios";
import {useParams} from 'react-router-dom';
import {
  Button,
  Col,
  message,
  Menu,
  Row
} from 'antd';

import {
  CloudArrowDown,
  FileEarmarkPlus,
  FileText,
  Magic
} from "react-bootstrap-icons";
import API from "../../constants/api";
import { userDataStore } from "../../store/user";
import useDocumentTitle from '../../utils/pageTitle';
import Spacer from '../common/spacer';
import CommentsDrawer from '../canvas/commentsDrawer';

const ProjectPlan = (propsData) => {
  const {
    projectDetails,
    updateProjectDetails,
    saveProjectDetailsObjects,
    planCommentsDrawer, 
    setPlanCommentsDrawer
  } = propsData;
  const [projectDescription, setProjectDescription] = useState(projectDetails.project_description);

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["clean"],
  ];

  const updateProjectDescription = (value) => {
    setProjectDescription(value);
    updateProjectDetails({...projectDetails, project_description: value});
  }

  return (
    <>
    {projectDetails && projectDetails.project_id &&
      <Row style={{height: '100%'}}>
        <Col span={3}>
          <div style={{padding: '50px 20px 50px 0'}}>
            <Button color="primary" type="text" icon={<FileEarmarkPlus />}>
                Add new document
            </Button>
            <Button color="primary" type="text" icon={<CloudArrowDown />}>
                Import document
            </Button>
            <Spacer size={20} />
            <ul className="btn-menu-list">
              <li>
                <Button type="link">
                  <FileText style={{verticalAlign: 'middle'}} /> Project Details
                </Button>
              </li>
            </ul>
          </div>
        </Col>
        <Col span={13} offset={1}>
          <div style={{padding: '50px 0'}}>
            <h2 style={{ margin: 0, padding: '0 0 50px 0', fontSize: '27px', fontWeight: 400 }}>Project Details</h2>
              <ReactQuill
                theme="snow"
                modules={{ toolbar: toolbarOptions }}
                value={projectDescription}
                onChange={(value) => updateProjectDescription(value)}
              />
            </div>
        </Col>
        <Col span={6} offset={1} className="drawer-col">
            <CommentsDrawer
              open={planCommentsDrawer}
              onClose={() => setPlanCommentsDrawer(false)}
              projectDetails={projectDetails}
              updateProjectDetails={updateProjectDetails}
              saveProjectDetails={console.log()}
              saveProjectDetailsObjects={saveProjectDetailsObjects}
            />

            <div className="help-blurb" style={{margin: '50px 50px 0 0'}}>
              <h3>
                <Magic />
                Let AI build your secure design
              </h3>
              <p>
                Provide as much detailed requirements for this project and let our AI agent build out the threat models and 
                action plan for you.
              </p>
              <p>
                Include details like:
                <ul>
                  <li>Functional requirements</li>
                  <li>Non-functional requirements</li>
                  <li>Infra structure requirements</li>
                  <li>Region requirements</li>
                  <li>and anything else</li>
                </ul>
              </p>
            </div>

        </Col>
      </Row>
      }
    </>
  );
};

export default ProjectPlan;